import { useCallback, useEffect, useState } from 'react';
import useDebounce from 'Shared/Hooks/useDebounce';
import { groupBy } from 'lodash';
import { searchSuppliers } from '../CatalogServices';

const useProductSupplier = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [searchSupplier, setSearchSupplier] = useState('');
  const targetSearch = useDebounce(searchSupplier, 500);

  const onSearchSuppliers = useCallback(async (search) => {
    const query = search.trim().length === 0 ? ' ' : search.trim();
    const suppRep = await searchSuppliers(query.toUpperCase());
    const grpLevel1 = groupBy(suppRep, 'supplierId');
    const formatSuppliers = Object.keys(grpLevel1).reduce((prev, supplierId) => {
      const current = {
        id: supplierId,
        name: grpLevel1[supplierId][0].supplierName,
        references: grpLevel1[supplierId].map(({ reference }) => reference),
      };
      return [...prev, current];
    }, []);
    setSuppliers(formatSuppliers);
  }, []);

  useEffect(() => onSearchSuppliers(targetSearch), [targetSearch]);
  return { suppliers, setSearchSupplier };
};

export default useProductSupplier;
