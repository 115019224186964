/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import useForm from 'Shared/Hooks/hooks/useForm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { toast } from 'react-toastify';
import axios from 'axios';
import { defaultMessage, getControlesChange, getOrders } from '../TraceabilityServices';
import { setLoadingTrace } from '../traceabilitySlice';
import Trace from './Trace';
import PageActions from './PageActions';
import { PopperMy } from '../constants';

let cancelToken;
const ControleTrace = () => {
  const [isLast, setIsLast] = useState(false);
  const [page, setPage] = useState(0);
  const [traces, setTraces] = useState([]);
  const [shadows, setShadows] = useState([]);

  const [parentTraces, setParentTraces] = useState([]);
  const dispatch = useDispatch();
  const { values, onChange } = useForm({ search: null, searchInput: '' });
  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState({ content: [] });
  const handleChange = (propery) => (e, value) => {
    onChange({ target: { name: propery, value } });
  };
  useEffect(async () => {
    try {
      dispatch(setLoadingTrace());
      const {
        changes,
        parents, page: currentPage, shadows: list,
      } = await getControlesChange(
        { page, id: values.search?.id },
      );
      setShadows(list);
      if (changes.length === 0) {
        setIsLast(true);
      } else {
        setIsLast(false);
        setTraces(changes);
        setPage(currentPage);
        setParentTraces(parents);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingTrace());
    }
  }, [page, values.search?.id, setShadows]);

  const getOrdersPage = useCallback(
    async () => {
      if (!values.searchInput) {
        return;
      }
      setLoading(true);
      try {
        if (typeof cancelToken !== typeof undefined) {
          cancelToken.cancel('Cancel');
        }
        cancelToken = axios.CancelToken.source();
        const data = await getOrders(
          {
            size: 30,
            page: 0,
          },
          { reference: values.searchInput },
          { cancelToken: cancelToken.token },
        );
        setOrders(data);
      } catch (e) {
        if (e.message !== 'Cancel') {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        }
      } finally {
        setLoading(false);
      }
    },
    [values.searchInput],
  );

  useEffect(() => {
    getOrdersPage();
  }, [getOrdersPage]);
  const lines = useMemo(() => shadows.map((shadow) => ({ id: shadow.it.id, property: shadow.it.name })), [shadows]);

  return (
    <>
      {loading && <GlobalLoader size="100vh" />}

      <Autocomplete
        PopperComponent={PopperMy}
        // classes={{ root: classes.filter }}
        options={orders?.content}
        onChange={handleChange('search')}
        disablePortal
        value={values.search}
        size="small"
        onClick={(e) => { e.stopPropagation(); }}
        autoHighlight
        getOptionLabel={(option) => option.reference}
        ListboxComponent={ListboxComponent}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Rechercher"
            variant="outlined"
            name="searchInput"
            onChange={onChange}
            value={values.searchInput}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
      />
      <PageActions page={page} setPage={setPage} isLast={isLast} />
      {traces.map((trace) => (
        <Trace
          key={`key-trace-card-${trace.commitMetadata.id}`}
          changes={trace.changes}
          commitMetadata={trace.commitMetadata}
          parents={parentTraces}
          lines={lines}
          isDoc
        />
      ))}
    </>
  );
};

export default ControleTrace;
