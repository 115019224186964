import { useState } from 'react';

const useMultiCheck = () => {
  const [selectedLines, setSelectedLines] = useState([]);
  const [unselected, setUnselected] = useState([]);
  const [isAll, setIsAll] = useState(false);

  const onCheckOne = (id) => {
    if (unselected.includes(id)) {
      setUnselected(unselected.filter((item) => item !== id));
    } else {
      setUnselected([...unselected, id]);
    }
  };

  const onCheckAll = () => {
    setIsAll(!isAll);
    setUnselected([]);
    setSelectedLines([]);
  };
  const onRest = () => {
    setIsAll(false);
    setUnselected([]);
    setSelectedLines([]);
  };

  const onSelectOneLine = (id) => {
    if (isAll) {
      onCheckOne(id);
      return;
    }

    if (selectedLines.includes(id)) {
      setSelectedLines(selectedLines.filter((item) => item !== id));
    } else {
      setSelectedLines([...selectedLines, id]);
    }
  };
  return {
    isAll, unselected, onCheckOne, onCheckAll, onRest, selectedLines, onSelectOneLine,
  };
};

export default useMultiCheck;
