/* eslint-disable eqeqeq */
import React, {
  useCallback,
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import Input from 'Shared/Input';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import EnhancedTable from 'Shared/EnhancedTable';
import Select from 'Shared/Select';
import SearchIcon from '@mui/icons-material/Search'; import useForm from 'Shared/Hooks/hooks/useForm';
import {
  Box, Button, Checkbox,
  Divider, FormControl,
  FormControlLabel, FormGroup,
  FormLabel, Grid, IconButton, List, ListItem, ListItemText, Radio, RadioGroup, Slider, Typography,
} from '@mui/material';
import usePrevious from 'Shared/Hooks/usePrevious';
import CancelIcon from '@mui/icons-material/Cancel';
import ReferencialContext from 'Shared/ReferencialContext';
import { ANNOUCE_TYPE, columnsProduct } from './constants';

export const scalePrice = (x) => ((x * 10000) / 100);

export const OtherFilters = ({
  title, items, filters, setPrices, onChangeFilter, isBrand, isPrice, hideTitle, displayCancelIcon,
}) => {
  const [value, setValue] = React.useState([0, 100]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <RenderOnDemandThenHide visible={!hideTitle}>
        <Typography style={{
          color: '#4D4D4D', fontSize: 18, fontWeight: 600, textTransform: 'uppercase', marginBottom: 2.6,
        }}
        >
          {title}
        </Typography>
      </RenderOnDemandThenHide>
      {isPrice
        ? (
          <Box style={{ width: '100%' }}>
            <Slider
              style={{ '& .MuiSlider-thumb': { borderRadius: 0, width: 13, height: 15 } }}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              onChangeCommitted={() => setPrices([scalePrice(value[0]), scalePrice(value[1])])}
              valueLabelFormat={(x) => (`${x}DHs`)}
              scale={scalePrice}
            />
          </Box>
        )
        : (
          <FormGroup>
            {items.map((item) => {
              const element = isBrand ? { id: item?.id, name: item?.name } : item;
              return (
                <>
                  <FormControlLabel
                    key={element.id}
                    control={(
                      <Checkbox
                          // eslint-disable-next-line max-len
                        onChange={() => onChangeFilter(element.id, element.subFamilies, false, isBrand)}
                        checked={filters.includes(element.id)}
                        defaultChecked
                      />
                )}
                    style={{
                      color: '#4D4D4D',
                      textTransform: 'capitalize',
                      fontSize: 10,
                      fontWeight: 600,
                      '.MuiTypography-root': {
                        width: '100%',
                      },
                    }}
                    label={(
                      <Box style={{ width: '100%' }}>
                        {element?.name?.toLowerCase && element?.name?.toLowerCase()}
                        {displayCancelIcon && (
                          <CancelIcon style={{
                            marginTop: '3px', fontSize: 12, float: 'right', color: '#007BC6',
                          }}
                          />
                        )}
                      </Box>
                      )}
                  />
                </>
              );
            })}
          </FormGroup>
        )}
    </Box>
  );
};

const Families = ({
  items, filters, onChangeFilter, hideTitle,
}) => {
  const {
    state: {
      referencial,
    },
  } = useContext(ReferencialContext);
  const [openedFamilies, setOpenedFamilies] = useState([]);

  const onOpenSubFamilies = (id) => {
    if (openedFamilies.includes(id)) {
      setOpenedFamilies(openedFamilies.filter((item) => item !== id));
    } else {
      setOpenedFamilies([...openedFamilies, id]);
    }
  };
  return (
    <Box>
      <RenderOnDemandThenHide visible={!hideTitle}>
        <Typography style={{
          color: '#4D4D4D', fontSize: 18, fontWeight: 600, textTransform: 'uppercase', marginBottom: 2.6,
        }}
        >
          Filtre par catégories
        </Typography>
      </RenderOnDemandThenHide>
      <FormGroup>
        {items && items.map((item) => {
          const subFamilies = referencial.families
            ?.filter((f) => f.parentId === item.id);
          const subIds = subFamilies?.map((sub) => sub.id);
          const selectedSubIds = filters.filter((sub) => subIds.includes(sub));
          return (
            <>
              <Box style={{ display: 'block', position: 'relative' }}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      onChange={() => onChangeFilter(item.id, subFamilies, true)}
                      checked={subIds.length === selectedSubIds.length}
                        // eslint-disable-next-line max-len
                      indeterminate={subIds.length > 0 && selectedSubIds.length > 0 && subIds.length !== selectedSubIds.length}
                      defaultChecked
                    />
                    )}
                  style={{
                    color: '#4D4D4D', textTransform: 'capitalize', fontSize: 10, fontWeight: 600,
                  }}
                  label={(
                    <>
                      <span style={{ color: '#4D4D4D' }}>{item?.name?.toLowerCase()}</span>
                      <span style={{ color: '#CCC', fontSize: '10px' }}>
                        {`(${referencial.families
                          .filter((f) => f.parentId === item.id)?.length})`}

                      </span>
                    </>
                    )}
                />
                <IconButton style={{ color: '#292D32', position: 'absolute', right: -4 }} onClick={() => onOpenSubFamilies(item.id)} size="small">
                  {openedFamilies.includes(item.id) ? '-' : '+' }
                </IconButton>
              </Box>
              <RenderOnDemandThenHide visible={openedFamilies.includes(item.id)}>
                {referencial.families
                  .filter((f) => f.parentId === item.id)?.map((subItem) => (
                    <FormControlLabel
                      key={subItem.id}
                      control={(
                        <Checkbox
                          onChange={() => onChangeFilter(subItem.id)}
                          checked={filters.includes(subItem.id)}
                          defaultChecked
                        />
                    )}
                      style={{
                        paddingLeft: 4, color: '#8F8F8F', fontSize: 8,
                      }}
                      label={(<span style={{ color: '#4D4D4D' }}>{subItem?.name}</span>)}
                    />
                  ))}
              </RenderOnDemandThenHide>
            </>
          );
        })}
      </FormGroup>
    </Box>
  );
};
const SelectedItems = ({
  categories, selected, removeOne,
}) => (
  <Box style={{
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
    backgroundColor: '#FFF',
    height: '252px',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '7px',
    },
    '::-webkit-scrollbar-track': {
      background: '#FFF',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#D8D8D8',
      borderRadius: '10px',
      '& :hover': {
        background: '#888',
      },
    },
  }}
  >
    { selected?.length > 0 ? (
      <List dense>
        {selected.map((item) => {
          const categoryAndSub = categories.find((element) => element.id === item.family);
          return item.brands.map((brand) => (
            <>
              <ListItem>
                <ListItemText
                  primary={`${categoryAndSub?.parent} > ${categoryAndSub?.name} (${brand})`}
                />
                <IconButton
                  onClick={() => removeOne(item.family, brand)}
                  edge="end"
                  aria-label="delete"
                  size="large"
                >
                  <Typography style={{ color: '#007BC6', fontWeight: 600 }}>Retirer</Typography>
                </IconButton>
              </ListItem>
              <Divider />
            </>
          ));
        })}
      </List>
    )
      : (
        <Box style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%',
        }}
        >
          <Typography style={{
            color: '#CCCCCC',
            fontSize: 16,
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: 2,
          }}
          >
            Liste des catégories/Marque
          </Typography>
          <Typography style={{
            color: '#4D4D4D',
            fontSize: 14,
            textAlign: 'center',
          }}
          >
            La liste des catégories avec la ou les marques pour lesquels la promotion
            sera appiliquée seront affichées ici
          </Typography>
        </Box>
      )}
  </Box>
);

const Options = ({ children, disabled }) => {
  const ref = useRef();
  return (
    <Box style={{ position: 'relative' }}>
      <RenderOnDemandThenHide visible={disabled}>
        <Box style={{
          position: 'absolute', width: '100%', height: '100%', background: 'rgba(50,50,50,0.3)',
        }}
        />
      </RenderOnDemandThenHide>
      <Box
        ref={ref}
        style={{
          padding: '20px 8px 26px',
          height: '252px',
          maxHeight: '252px',
          backgroundColor: '#FFF',
          borderRadius: '5px',
          border: '1px solid #CCCCCC',
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
          overflowY: 'auto',
          '::-webkit-scrollbar': {
            width: '7px',
          },
          '::-webkit-scrollbar-track': {
            background: '#FFF',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#D8D8D8',
            borderRadius: '10px',
            '& :hover': {
              background: '#888',
            },
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const getSubFamilies = (categories, families) => categories?.flatMap(
  (i) => families.filter((f) => f.parentId === i.id).map((k) => ({ ...k, parent: i.name })),
);

const Category = ({ values, onChange, categories }) => {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [filterBrands, setFilterBrands] = useState([]);
  const [brands, setBrands] = useState(values?.brands || []);
  const [categoryBrands, setCategoryBrands] = useState(values?.linkedFamilies || []);
  const {
    state: {
      referencial,
    },
  } = useContext(ReferencialContext);
  const familiesAndSub = getSubFamilies(categories, referencial.families);
  useEffect(() => {
    const brandsData = brands?.map((item) => item.name) || [];
    setFilterBrands(brandsData);
  }, [brands, setFilterBrands]);
  useEffect(() => {
    onChange({ target: { name: 'linkedFamilies', value: categoryBrands } });
  }, [categoryBrands]);
  useEffect(async () => {
    // let params = '?';
    // selectedCategory?.forEach((item) => {
    //   if (!brands.includes(item)) {
    //     params += 'family='.concat(item).concat('&');
    //   }
    //   return item;
    // });
    const bIds = [
      ...(new Set(referencial.products
        .filter((p) => selectedCategory.includes(p.fTwo)
        || selectedCategory.includes(p.fId)).map((po) => po.bId)))];
    const bds = referencial.brands.filter((b) => bIds.includes(b.id));
    setBrands(bds);
    if (selectedCategory.length > 0) {
      setFilterBrands(bds);
    }
    // callApi(
    //   () => getBrands(params),
    //   (resp) => {
    //     const brandsData = resp?.map((item) => item.name) || [];
    //     setBrands(brandsData);
    //     if (selectedCategory.length > 0) {
    //       setFilterBrands(brandsData);
    //     }
    //   },
    // );
  }, [selectedCategory]);

  const onHandleFiltersBrand = (id) => {
    if (!filterBrands.includes(id)) {
      setFilterBrands([...filterBrands, id]);
    } else {
      setFilterBrands(filterBrands.filter((item) => item !== id));
    }
  };

  const onHandleFilters = (id, subFamilies, isParent, isBrand) => {
    if (isBrand) {
      onHandleFiltersBrand(id);
    } else if (!isParent) {
      if (selectedCategory.length === 0 || !selectedCategory.includes(id)) {
        setSelectedCategory([id]);
      } else {
        setSelectedCategory([]);
      }
      setFilterBrands([]);
    }
  };

  const addSelectedBrands = () => {
    setCategoryBrands([...categoryBrands, { family: selectedCategory[0], brands: filterBrands }]);
    setSelectedCategory([]);
    setFilterBrands([]);
  };

  const onRemoveOne = (family, brand) => {
    const removeBrand = categoryBrands.map((item) => {
      if (item.family === family) {
        return { ...item, brands: item.brands.filter((brd) => brd !== brand) };
      }
      return item;
    });
    setCategoryBrands(removeBrand.filter((item) => item.brands.length !== 0));
  };
  return (
    <>
      <Grid item xs={5}>
        <Typography
          style={{
            color: '#4D4D4D',
            fontSize: '14px',
            fontWeight: 600,
            marginBottom: '22px',
          }}
        >
          Liste des catégories
        </Typography>
        <Options>
          <Families
            items={categories}
            filters={selectedCategory}
            onChangeFilter={onHandleFilters}
            hideTitle
          />
        </Options>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={5}>
        <Typography
          style={{
            color: '#4D4D4D',
            fontSize: '14px',
            fontWeight: 600,
            marginBottom: '22px',
          }}
        >
          Liste des marques liées aux catégories
        </Typography>
        <Options disabled={selectedCategory.length === 0}>
          <OtherFilters
            isBrand
            items={brands}
            filters={filterBrands}
            onChangeFilter={onHandleFilters}
            hideTitle
          />
        </Options>
      </Grid>
      <Grid xs={12}>
        <Button
          disabled={selectedCategory.length === 0 || filterBrands.length === 0}
          onClick={addSelectedBrands}
          variant="contained"
          color="inherit"
          style={{
            marginTop: '20px', float: 'right', border: '2px solid #007BC6', color: '#007BC6', backgroundColor: '#FFF', textTransform: 'capitalize',
          }}
        >
          Ajouter
        </Button>
      </Grid>
      <Grid item xs={12}>
        <SelectedItems
          categories={familiesAndSub}
          selected={categoryBrands}
          removeOne={onRemoveOne}
        />
      </Grid>
    </>
  );
};

const Products = ({ values, onChange, categories }) => {
  const [touched, setTouched] = useState(false);
  const [page, setPage] = useState(0);
  const [brands, setBrands] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [products, setProducts] = useState([]);
  const [promoIds, setPromoIds] = useState(values?.promoProducts || []);
  const [infoIds, setInfoIds] = useState(values?.infoProducts || []);
  const [newIds, setNewIds] = useState(values?.newProducts || []);
  const {
    state: {
      referencial,
    },
  } = useContext(ReferencialContext);
  useEffect(() => {
    if (!products.length && referencial.products) {
      setProducts(referencial.products.filter((p) => p.iA));
    }
  }, [referencial.products, products, setProducts]);
  const { values: formFitlers, onChange: onChangeFilters } = useForm({
    ref: '',
    family: values.families?.length > 0 ? values.families[0] : '',
    brand: values.brands?.length > 0 ? values.brands[0] : '',
  });
  const prevFormFitlers = usePrevious(formFitlers);
  // const deboucedFilter = useDebounce(formFitlers, 500);
  const familiesAndSub = getSubFamilies(categories, referencial.families);
  const setAll = (all) => {
    onChange({ target: { name: 'all', value: all } });
  };
  // const getData = useCallback(
  //   (params) => {
  //     const body = {
  //       search: formFitlers.ref || null,
  //       families: formFitlers.family !== '' ? [formFitlers.family] : null,
  //       brands: formFitlers.brand !== '' ? [formFitlers.brand] : null,
  //     };
  //     onChange({ target: { name: 'filter', value: formFitlers } });
  // eslint-disable-next-line max-len
  //     callApi(() => getProductsData(`page=${params?.page || 0}&size=${params?.size || 10}&sort=${params?.sort || ''}`, body), setProducts);
  //   },
  //   [formFitlers],
  // );
  // useEffect(() => {
  //   if (deboucedFilter) {
  //     getData();
  //   }
  // }, [deboucedFilter]);

  useEffect(() => {
    if (formFitlers.family !== '') {
      const brandIds = [
        ...new Set(referencial.products
          .filter((p) => p.fTwo == formFitlers.family
          || p.fId == formFitlers.family).map((p) => p.bId)),
      ];
      setBrands(referencial.brands.filter((b) => brandIds.includes(b.id)));
    }
    onChange({ target: { name: 'filter', value: formFitlers } });
    if (touched) {
      // dont change select all if search by ref is changed
      if (prevFormFitlers.ref === formFitlers.ref) {
        setNewIds([]);
        setPromoIds([]);
        setInfoIds([]);
        setAll({ promo: false, new: false, info: false });
        onChange({ name: 'excludeNewProducts', value: [] });
        onChange({ name: 'promoProducts', value: [] });
      }
    }
  }, [formFitlers, touched]);
  useEffect(() => {
    // onChange({ name: 'newProducts', value: newIds });
    onChange({ name: 'newProducts', value: values.all.new ? [] : newIds });
    onChange({ name: 'excludeNewProducts', value: values.all.new ? newIds : [] });
  }, [newIds]);

  useEffect(() => {
    // onChange({ name: 'promoProducts', value: promoIds });
    onChange({ name: 'promoProducts', value: values.all.promo ? [] : promoIds });
    onChange({ name: 'excludePromoProducts', value: values.all.promo ? promoIds : [] });
  }, [promoIds]);

  useEffect(() => {
    onChange({ name: 'infoProducts', value: values.all.info ? [] : infoIds });
    onChange({ name: 'excludeInfoProducts', value: values.all.info ? infoIds : [] });
  }, [infoIds]);

  const onSelectAllPromo = () => {
    // setAll({ ...all, promo: !all.promo });
    // setPromoIds(!all.promo ? products?.content?.map((item) => item.reference) : []);
    setAll({ ...values.all, promo: !values.all.promo });
    setPromoIds([]);
  };

  const onSelectAllNew = () => {
    // setAll({ ...all, new: !all.new });
    // setNewIds(!all.new ? products?.content?.map((item) => item.reference) : []);
    setAll({ ...values.all, new: !values.all.new });
    setNewIds([]);
  };

  const onSelectAllInfo = () => {
    setAll({ ...values.all, info: !values.all.info });
    setInfoIds([]);
  };

  const onCheckOn = (isNew, isInfo, id) => () => {
    if (isNew) {
      if (newIds.includes(id)) {
        setNewIds(newIds.filter((item) => item !== id));
      } else {
        setNewIds([...newIds, id]);
      }
    } else if (isInfo) {
      if (infoIds.includes(id)) {
        setInfoIds(infoIds.filter((item) => item !== id));
      } else {
        setInfoIds([...infoIds, id]);
      }
    } else if (promoIds.includes(id)) {
      setPromoIds(promoIds.filter((item) => item !== id));
    } else {
      setPromoIds([...promoIds, id]);
    }
  };

  const onChangeFamily = (e) => {
    onChangeFilters(e);
    onChangeFilters({ target: { name: 'brand', value: '' } });
    setTouched(true);
  };

  const isCheckedPromo = useCallback((id) => (promoIds.includes(id)
    ? !values.all.promo : values.all.promo),
  [promoIds, values]);

  const isCheckedNew = useCallback((id) => (newIds.includes(id) ? !values.all.new : values.all.new),
    [newIds, values]);
  const isCheckedInfo = useCallback((id) => (infoIds.includes(id)
    ? !values.all.info : values.all.info),
  [infoIds, values]);
  const formattedProducts = useMemo(() => products
    ?.filter((p) => (
      !formFitlers.ref || p.reference.toLowerCase().includes(formFitlers.ref.toLowerCase()))
    && (!formFitlers.family || p.fTwo == formFitlers.family || p.fId == formFitlers.family)
    && (!formFitlers.brand || formFitlers.brand == p.bId))
    ?.slice(page * rowsPerPage, rowsPerPage * (page + 1)).map((item) => (
    // eslint-disable-next-line max-len
      {
        ...item,
        family: referencial.families.find((f) => f.id === item.fId)?.name,
        promo: isCheckedPromo(item.reference),
        new: isCheckedNew(item.reference),
        info: isCheckedInfo(item.reference),
      }
    )), [products, isCheckedPromo, isCheckedNew, isCheckedInfo, referencial.families,
    page, rowsPerPage, formFitlers]);
  const changePageAndRowsPerPage = (p, rp) => {
    if (p) {
      setPage(p);
    }
    if (rp) {
      setRowsPerPage(rp);
    }
  };
  return (
    <>
      <Grid item xs={12} style={{ paddingTop: '16px' }} alignItems="center">
        <Box style={{ display: 'inline-block' }}>
          <Input
            value={formFitlers.ref}
            onChange={onChangeFilters}
            hint="Recherche réf…."
            name="ref"
            large={false}
            iconEnd={<SearchIcon style={{ cursor: 'pointer' }} color="primary" />}
          />
        </Box>
        <Box style={{ display: 'inline-block', marginLeft: '20px' }}>
          <Select options={familiesAndSub || []} value={formFitlers.family} label="Famille" name="family" onChange={onChangeFamily} />
        </Box>
        <Box style={{ display: 'inline-block', marginLeft: '20px' }}>
          <Select options={brands} value={formFitlers.brand} label="Marque" name="brand" onChange={onChangeFilters} disabled={brands?.length === 0} />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: '16px' }}>
        <EnhancedTable
          pagination
          // getData={getData}
          update={false}
          updateData={changePageAndRowsPerPage}
          rows={formattedProducts}
          headCells={
              columnsProduct(onSelectAllPromo,
                onSelectAllNew, onSelectAllInfo, onCheckOn, values.all)
            }
          count={products?.length}
          rowsPerPageOptions={[10, 15, 24]}
        />
      </Grid>
    </>
  );
};
const ProductCategory = ({
  values, onChange, onClose, onPrev, onSubmit, categories,
}) => (
  <Grid container style={{ paddingTop: 2.5 }} spacing={4}>
    <Grid item xs={2} />
    <Grid item xs={8}>
      <FormControl>
        <FormLabel
          id="buttons-group-label"
          style={{
            color: '#8F8F8F',
            fontSize: '14px',
            fontWeight: 600,
            marginBottom: '18px',
          }}
        >
          L’annonce concerne:
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="buttons-group-label"
          name="productToggle"
        >
          <FormControlLabel
            value={ANNOUCE_TYPE.PRODUCT}
            checked={values?.productToggle === ANNOUCE_TYPE.PRODUCT}
            style={{
              color: '#4D4D4D',
              fontSize: '14px',
              fontWeight: 500,
            }}
            onChange={onChange}
            control={<Radio />}
            label="Certains produits"
          />
          <FormControlLabel
            value={ANNOUCE_TYPE.CATEGORY_PRODUCT}
            checked={values?.productToggle === ANNOUCE_TYPE.CATEGORY_PRODUCT}
            style={{
              marginLeft: '172px',
              color: '#4D4D4D',
              fontSize: '14px',
              fontWeight: 500,
            }}
            onChange={onChange}
            control={<Radio />}
            label="Une catégorie de produit"
          />
        </RadioGroup>
      </FormControl>
      <Divider />
    </Grid>
    <Grid item xs={2} />
    <RenderOnDemandThenHide visible={values?.productToggle === ANNOUCE_TYPE.PRODUCT}>
      <Products values={values} onChange={onChange} categories={categories} />
    </RenderOnDemandThenHide>
    <RenderOnDemandThenHide visible={values?.productToggle === ANNOUCE_TYPE.CATEGORY_PRODUCT}>
      <Category values={values} onChange={onChange} categories={categories} />
    </RenderOnDemandThenHide>
    <Grid item xs={2} />
    <Grid item xs={3}>
      <Button
        fullWidth
        onClick={onClose}
        variant="contained"
        color="inherit"
        style={{
          border: '1px solid #CCCCCC', color: '#CCCCCC', backgroundColor: '#FFFFFF', textTransform: 'capitalize',
        }}
      >
        Annuler
      </Button>
    </Grid>
    <Grid item xs={1} />
    <Grid item xs={3}>
      <Button
        fullWidth
        onClick={onPrev}
        variant="contained"
        color="inherit"
        style={{
          border: '1px solid #007BC6', color: '#007BC6', backgroundColor: '#FFFFFF', textTransform: 'capitalize',
        }}
      >
        Précédent
      </Button>
    </Grid>
    <Grid item xs={3}>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        color="primary"
        style={{ textTransform: 'capitalize' }}
      >
        Valider
      </Button>
    </Grid>
  </Grid>
);

export default ProductCategory;
