import React, { useMemo, useContext } from 'react';
import { Grid, Button } from '@mui/material';
import RenderInput from 'Shared/RenderInput';
import ReferencialContext from 'Shared/ReferencialContext';
import { toast } from 'react-toastify';
import useForm from '../../../Shared/Hooks/hooks/useForm';
import { addCLient, defaultMessage } from '../RecoveryServices';

const Fields = [
  {
    type: 'text',
    id: 'code',
    label: 'Code client',
  },
  {
    type: 'text',
    id: 'name',
    label: 'Name client',
  },
  {
    type: 'autocomplete',
    id: 'city',
    label: 'Ville',
    listName: 'cities',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'autocomplete',
    id: 'zone',
    label: 'Zone',
    listName: 'areas',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
];

const AddClient = () => {
  const {
    state: {
      referencial: { clients: clientList },
    },
  } = useContext(ReferencialContext);
  const { values, onChange, onChangeSelect } = useForm({});

  const lists = useMemo(() => ({
    cities: (clientList || []).map((c) => c.city).unique(),
    areas: (clientList || []).map((c) => c.zone).unique(),
  }),
  [clientList]);

  const handleAdd = async () => {
    try {
      // setLoading(true);
      await addCLient(values);
      toast.success('Client modifié avec succès');
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      // setLoading(false);
    }
  };
  return (
    <Grid container spacing={2} style={{ padding: '20px', margin: 0, width: '100%' }}>
      {Fields.map((field) => (
        <Grid xs={6} item>
          <RenderInput
            input={field.type === 'autocomplete' ? { ...field, [field.listName]: lists[field.listName] } : field}
            value={values[field.id]}
            name={field.id}
            handleChange={field.type === 'autocomplete' ? (e, v) => onChangeSelect(field.id, v) : (e, v) => onChange(e, v)}
          />
        </Grid>
      ))}
      <Grid container spacing={2} style={{ padding: '20px', margin: 0, width: '100%' }} direction="row-reverse">
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleAdd}>Valider</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddClient;
