import React, { useEffect, useState } from 'react';
import {
  Box, Typography, IconButton, TextField, Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ConfirmRemoveDialog from 'Shared/ConfirmDialog/ConfirmRemoveDialog';
import ConfirmDialog from 'Shared/ConfirmDialog';
import {
  defaultMessage, getDevices, deleteDevice, addDevice,
} from '../SettingsServices';
import useForm from '../../../Shared/Hooks/hooks/useForm';

const useStyles = makeStyles(() => ({
  card: {
    background: '#FFF',
    boxShadow: '0px 12px 22px #0000000D',
    borderRadius: '12px',
    margin: '4px 8px',
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 1 calc(20% - 1px)',
  },
  title: { font: 'normal normal 600 16px Montserrat', fontWeight: 'bold' },
  searchArea: {
    backgroundColor: '#E9EBED',
    padding: '16px',
    borderRadius: '0px 0px 12px 12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    width: '200px',
  },
  newDevice: { border: '1px solid #007BC6;', width: '400px' },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  notched: {
    border: 'none',
  },
}));
const Device = ({
  deviceId, id, onChangeLoader, onSuccessRemove,
}) => {
  const classes = useStyles();
  const [remove, setRemove] = useState(false);

  const onHandelRemove = async () => {
    try {
      onChangeLoader(true);
      await deleteDevice(id);
      onSuccessRemove(id, true);
      toast.success('Device  a été bien supprimé');
    } catch (e) {
      toast.error('Impossible de supprimer le device!');
    } finally {
      setRemove(false);
      onChangeLoader(false);
    }
  };

  return (
    <>
      {remove && (
        <ConfirmRemoveDialog
          title="Suppresion du device"
          content="Êtes-vous sûr de vouloir supprimer ce device ?"
          handleClose={() => setRemove(false)}
          handleClick={onHandelRemove}
        />
      )}
      <Box className={classes.card}>
        <Typography className={classes.title}>{`Device ${deviceId}`}</Typography>
        <IconButton aria-label="delete" size="small" style={{ color: '#F86060' }} onClick={() => setRemove(true)}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </>
  );
};

const Index = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { values, onChange } = useForm({ search: '', deviceId: '' });

  useEffect(async () => {
    try {
      setLoading(true);

      const data = await getDevices();
      setDevices(data);
    } catch (e) {
      toast.error(defaultMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSuccessRemove = (id, isRemoved) => {
    if (isRemoved) {
      setDevices(devices.filter((device) => device.id !== id));
    }
  };

  const onHandelSave = async () => {
    const isExist = devices.find(({ deviceId }) => deviceId === values.deviceId);
    if (isExist || values.deviceId.length === 0) {
      toast.error('Device existe déjà');
      return;
    }
    try {
      setLoading(true);
      const device = await addDevice({ deviceId: values.deviceId });
      setOpen(false);
      setDevices([...devices, device]);
      toast.success('Device a été bien ajouté');
    } catch (e) {
      toast.error(defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Box className={classes.searchArea}>
        <TextField
          variant="outlined"
          onChange={onChange}
          value={values.search}
          label="chercher"
          name="search"
          size="small"
          className={classes.filter}
          style={{ maxWidth: 350 }}
          InputProps={{
            classes: { notchedOutline: classes.notched },
          }}
          InputLabelProps={{
            classes: { root: classes.label },
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
          style={{
            borderRadius: '12px', border: '2px solid #007BC6', color: '#FFF', backgroundColor: '#007BC6', textTransform: 'none',
          }}
        >
          + Ajouter un appareil
        </Button>
      </Box>
      {open && (
        <ConfirmDialog
          title="Ajouter Un Appareil"
          content={(
            <TextField
              variant="outlined"
              onChange={onChange}
              value={values.deviceId}
              label="device ID"
              name="deviceId"
              size="small"
              className={[classes.filter, classes.newDevice]}
              style={{ maxWidth: 400 }}
              InputProps={{
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          koLabelBtn="Annuler"
          okLabelBtn="ENREGISTRÉ"
          okStyle={{
            borderRadius: '12px', color: '#FFF', backgroundColor: '#007BC6', boxShadow: '0px 4px 10px #108CD650',
          }}
          handleClose={() => setOpen(false)}
          handleClick={onHandelSave}
          okIcon={<SaveIcon />}
        />
      )}
      <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
        {devices.filter((device) => values.search === null || values.search === '' || device.deviceId.match(values.search) !== null)
          .map((device) => (
            <Device
              deviceId={device.deviceId}
              id={device.id}
              key={device.id}
              onChangeLoader={setLoading}
              onSuccessRemove={onSuccessRemove}
            />
          ))}
      </Box>
    </>
  );
};

export default Index;
