import { Box, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import ReferencialContext from 'Shared/ReferencialContext';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import useForm from 'Shared/Hooks/hooks/useForm';
import {
  columns,
  mapBlockageManuelTOValue,
  mapValueTOBlockage,
} from './constants';
import { updateClient } from '../SettingsServices';
import EditClient from './EditClient';

const useStyles = makeStyles(() => ({
  searchArea: {
    backgroundColor: '#E9EBED',
    padding: '16px',
    marginBottom: 10,
    borderRadius: '0px 0px 12px 12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    width: '200px',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  notched: {
    border: 'none',
  },
}));
const Clients = () => {
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [clients, setClients] = useState([]);
  const classes = useStyles();
  const { values, onChange } = useForm({ search: '' });
  const {
    state: {
      referencial: { clients: originalClients },
    },
  } = useContext(ReferencialContext);

  useEffect(() => {
    const filterBy = values.search.trim();
    if (filterBy.length > 0) {
      setClients(originalClients?.filter(({ code }) => code.includes(filterBy)));
    } else {
      setClients(originalClients);
    }
  }, [values, originalClients]);
  const onHandleEdit = (client) => {
    setClientToEdit({
      ...client,
      blocked: mapBlockageManuelTOValue(client.blocked),
    });
    setOpenEdit(true);
  };

  const onHandleSubmit = async (client) => {
    try {
      setLoading(true);
      await updateClient({ ...client, blocked: mapValueTOBlockage(client.blocked) });
      const update = clients.map((item) => (item.code === client.code ? client : item));
      setClients(update);

      toast.success('Client a été bien modifier');
    } catch (e) {
      toast.error('Erreur de sauvegarde!');
    } finally {
      setOpenEdit(false);
      setClientToEdit(null);
      setLoading(false);
    }
  };

  return (
    <Box>
      {loading && <GlobalLoader size="100vh" />}
      {openEdit && clientToEdit && (
        <EditClient
          open={openEdit}
          onSubmit={onHandleSubmit}
          client={clientToEdit}
          setOpen={setOpenEdit}
        />
      )}
      <Box className={classes.searchArea}>
        <TextField
          variant="outlined"
          onChange={onChange}
          value={values.search}
          label="Chercher"
          name="search"
          size="small"
          className={classes.filter}
          style={{ maxWidth: 350 }}
          InputProps={{
            classes: { notchedOutline: classes.notched },
          }}
          InputLabelProps={{
            classes: { root: classes.label },
          }}
        />
      </Box>
      <EnhancedTable
        staticData
        rows={clients || []}
        headCells={columns(onHandleEdit)}
        count={clients?.length}
        rowsPerPageOptions={[20, 30, 40]}
        resetSort={values.search}
      />
    </Box>
  );
};

export default Clients;
