import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import CacheManager from 'Utils/CacheManager';
import ReferencialContext, { setReferencial } from 'Shared/ReferencialContext';

import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { useDispatch } from 'react-redux';
import { setAppBrands, setAppFamilies } from 'appSlice';
import {
  defaultMessage,
  getPartialyReferencialProducts,
  getReferencialProducts,
} from './ReferencialService';

const Referencial = ({ children }) => {
  const { dispatch } = useContext(ReferencialContext);
  const [loading, setLoading] = useState(false);
  const dispatchRedux = useDispatch();
  const fetchReferencial = useCallback(async () => {
    const data = await CacheManager.readData('referencial');
    const date = await CacheManager.readData('lastDateCall');
    setLoading(false);
    const nextDate = new Date();
    try {
      if (!data) {
        await getReferencialProducts(dispatch, 0);
        await CacheManager.writeData('lastDateCall', nextDate);
      } else {
        const ref = await getPartialyReferencialProducts(
          dispatch,
          date ? date.getTime() : new Date().getTime(),
        );
        const {
          families, products, brands, clients, expeditions, paymentModes, webVersion,
        } = ref;
        data.families = data.families.map((family) => {
          if (families.map((f) => f.id).includes(family.id)) {
            const found = families.find((f) => f.id === family.id);
            families.splice(families.indexOf(found), 1);
            return { ...family, ...found };
          }
          return family;
        });
        data.families = [...data.families, ...families];
        dispatchRedux(setAppFamilies(data.families));
        data.brands = data.brands.map((brand) => {
          if (brands.map((b) => b.id).includes(brand.id)) {
            const found = brands.find((b) => b.id === brand.id);
            brands.splice(brands.indexOf(found), 1);
            return { ...brand, ...found };
          }
          return brand;
        });
        data.brands = [...data.brands, ...brands];
        dispatchRedux(setAppBrands(data.brands));

        data.clients = data.clients.map((client) => {
          if (clients.map((c) => c.code).includes(client.code)) {
            const found = clients.find((b) => b.code === client.code);
            clients.splice(clients.indexOf(found), 1);
            return { ...client, ...found };
          }
          return client;
        });
        data.clients = [...data.clients, ...clients];

        data.expeditions = data.expeditions.map((expedition) => {
          if (expeditions.map((e) => e.code).includes(expedition.code)) {
            const found = expeditions.find((c) => c.code === expedition.code);
            expeditions.splice(expeditions.indexOf(found), 1);
            return { ...expedition, ...found };
          }
          return expedition;
        });
        data.expeditions = [...data.expeditions, ...expeditions];

        data.paymentModes = data.paymentModes.map((paymentMode) => {
          if (paymentModes.map((e) => e.code).includes(paymentMode.code)) {
            const found = paymentModes.find((c) => c.code === paymentMode.code);
            paymentModes.splice(paymentModes.indexOf(found), 1);
            return { ...paymentMode, ...found };
          }
          return paymentMode;
        });
        data.paymentModes = [...data.paymentModes, ...paymentModes];

        data.products = data.products.map((product) => {
          if (products.map((p) => p.id).includes(product.id)) {
            const found = products.find((p) => p.id === product.id);
            products.splice(products.indexOf(found), 1);
            return { ...product, ...found };
          }
          return product;
        });
        data.products = [...data.products, ...products];
        const previousVersion = data.webVersion;
        data.webVersion = webVersion;
        await CacheManager.writeData('referencial', data);
        await CacheManager.writeData('lastDateCall', nextDate);
        await dispatch(setReferencial(data));
        if (previousVersion < webVersion) {
          window.location.reload(true);
        }
      }
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [dispatch, getReferencialProducts, setReferencial, setLoading]);
  useEffect(() => {
    fetchReferencial();
    const timer = setInterval(async () => {
      fetchReferencial();
    }, 20000);
    return () => clearInterval(timer);
  }, [fetchReferencial]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {children}
    </>
  );
};

export default Referencial;
