import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import useForm from 'Shared/Hooks/hooks/useForm';
import ReferencialContext from 'Shared/ReferencialContext';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Trace from './Trace';
import { getProductsChange } from '../TraceabilityServices';
import useProductsRef from './useProductsRef';
import PageActions from './PageActions';
import { setLoadingTrace } from '../traceabilitySlice';
import { PopperMy } from '../constants';

const ProductTrace = () => {
  const [isLast, setIsLast] = useState(false);
  const [productsRef, setProductsRef] = useState([]);
  const { findProducts } = useProductsRef();
  const [page, setPage] = useState(0);
  const [traces, setTraces] = useState([]);
  const dispatch = useDispatch();
  const { values, onChange } = useForm({ search: null });

  const handleChange = (propery) => (e, value) => {
    onChange({ target: { name: propery, value } });
  };
  const {
    state: {
      referencial: { products },
    },
  } = useContext(ReferencialContext);
  useEffect(async () => {
    try {
      dispatch(setLoadingTrace());
      const { changes, page: currentPage } = await getProductsChange(
        { page, search: values.search?.reference },
      );
      if (changes.length === 0 && values.search === null) {
        setIsLast(true);
      } else {
        setIsLast(false);
        setTraces(changes);
        setPage(currentPage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingTrace());
    }
  }, [page, values.search]);

  useEffect(() => {
    setProductsRef(findProducts(traces));
  }, [traces]);

  return (
    <>
      <Autocomplete
        PopperComponent={PopperMy}
        // classes={{ root: classes.filter }}
        options={products || []}
        onChange={handleChange('search')}
        disablePortal
        value={values.search}
        size="small"
        onClick={(e) => { e.stopPropagation(); }}
        autoHighlight
        getOptionLabel={(option) => option.reference}
        ListboxComponent={ListboxComponent}
        filterOptions={(options, v) => options
          .filter((option) => option.reference?.toLowerCase().includes(v.inputValue.toLowerCase()))}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Rechercher"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            // InputProps={{
            //   ...params.InputProps,
            //   classes: { notchedOutline: classes.notched },
            // }}
            // InputLabelProps={{
            //   classes: { root: classes.label },
            // }}
          />
        )}
        popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
      />
      <PageActions page={page} setPage={setPage} isLast={isLast} />
      {traces.map((trace, index) => (
        <Trace
          // eslint-disable-next-line react/no-array-index-key
          key={`key-trace-card-${trace.commitMetadata.id}-${index}-${page}`}
          changes={trace.changes}
          productsRef={productsRef}
          commitMetadata={trace.commitMetadata}
        />
      ))}
    </>
  );
};

export default ProductTrace;
