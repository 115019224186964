import { Box, DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import React, { useRef } from 'react';
import { withStyles } from '@mui/styles';
import MuiDialogContent from '@mui/material/DialogContent';
import SaveIcon from '@mui/icons-material/Save';
import ButtonApp from 'Shared/Button';
import DEFAULT_PRODUCT from 'Images/default_product.png';

const Actions = ({
  handleClose,
  labelKo,
  isOkDisabled,
  styleKo,
  styleOk,
  onHandleSubmit,
  endIcon,
  labelOk,
  actionStyle,
}) => (
  <DialogActions style={{ paddingRight: '16px', paddingBottom: '30px', ...actionStyle }}>
    {labelKo && (
      <ButtonApp
        onClick={handleClose}
        style={{
          background: '#fff',
          boxShadow: '0px 4px 10px #BEBEBE50',
          color: 'black',
          ...styleKo,
        }}
        label={labelKo}
      />
    )}
    <ButtonApp
      disabled={isOkDisabled}
      onClick={onHandleSubmit}
      endIcon={endIcon && <SaveIcon />}
      label={labelOk}
      style={{ ...styleOk }}
    />
  </DialogActions>
);

export const HeaderImage = ({ image, alt }) => {
  const headerRef = useRef();
  return (
    <img
      ref={headerRef}
      // eslint-disable-next-line no-return-assign, no-param-reassign
      onError={() => (headerRef.current.src = DEFAULT_PRODUCT)}
      style={{
        minWidth: '70px', maxWidth: '70px', height: '70px', borderRadius: '10px', marginRight: '10px',
      }}
      src={`https://media.copima.ma/products/${image}`}
      alt={alt}
    />
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflowY: 'unset',
  },
}))(MuiDialogContent);
const DialogCmp = ({
  open,
  children,
  title,
  onHandleSubmit,
  handleClose,
  subTitle,
  scroll,
  headerImg,
  fullWidth = true,
  labelOk = 'Sauvegarder',
  labelKo = 'Annuler',
  endIcon = true,
  isOkDisabled = false,
  styleOk = {},
  styleKo = {},
  withAction = true,
  actionStyle = {},
  actionsTop = false,
  maxWidth = 'md',
}) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    maxWidth={maxWidth}
    scroll={scroll}
    fullWidth={fullWidth}
    open={open}
    PaperProps={{
      style: {
        background: '#F9FAFC 0% 0% no-repeat padding-box',
        border: '1px solid #C5C5C5',
        borderRadius: 12,
      },
    }}
    BackdropProps={{
      style: {
        background: '#245283 0% 0% no-repeat padding-box',
        boxShadow: '0px 12px 26px #0000000D',
        opacity: 0.67,
      },
    }}
  >
    <Box style={{ padding: '16px 34px', paddingTop: '25px' }}>
      <Box sx={{ display: 'flex' }}>
        {headerImg
          ? (<HeaderImage image={headerImg} alt={subTitle} />) : null}
        <Box sx={{ width: '100%' }}>
          <Box style={{ display: 'flex' }}>
            <Typography style={{ font: 'normal normal 600 24px Montserrat', flexGrow: 1 }}>
              {title}
            </Typography>
            {actionsTop && withAction ? (
              <Actions
                handleClose={handleClose}
                labelKo={labelKo}
                isOkDisabled={isOkDisabled}
                styleKo={styleKo}
                styleOk={styleOk}
                onHandleSubmit={onHandleSubmit}
                endIcon={endIcon}
                labelOk={labelOk}
                actionStyle={actionStyle}
              />
            ) : (
              <IconButton
                style={{
                  background: '#C5C5C5 0% 0% no-repeat padding-box',
                  borderRadius: 24,
                  opacity: 0.53,
                  maxHeight: '34px',
                }}
                size="small"
                onClick={handleClose}
              >
                <CloseIcon style={{ color: '#707070' }} />
              </IconButton>
            )}
          </Box>
          {subTitle && (
            <Typography style={{ font: 'normal 600 14px Montserrat', color: '#BDBDBD' }}>
              {subTitle}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
    <DialogContent>{children}</DialogContent>
    {!actionsTop && withAction ? (
      <Actions
        handleClose={handleClose}
        labelKo={labelKo}
        isOkDisabled={isOkDisabled}
        styleKo={styleKo}
        styleOk={styleOk}
        onHandleSubmit={onHandleSubmit}
        endIcon={endIcon}
        labelOk={labelOk}
        actionStyle={actionStyle}
      />
    ) : null}
  </Dialog>
);

export default DialogCmp;
