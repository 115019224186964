import { getDocumentById, getInvoicesTotalByFolderId } from 'Components/Import/importServices';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { getTotalCharge, sumTotalPrices } from './constants';

const useInvoiceDetails = (folderId, canGetDocument = false) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [firstCall, setFirstCall] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [totalDocument, setTotalDocument] = useState(0);

  useEffect(async () => {
    if (!folderId || !canGetDocument) {
      return;
    }
    const response = await getDocumentById(folderId);
    setTotalDocument(getTotalCharge(response));
  }, [folderId, canGetDocument]);

  useEffect(async () => {
    if (!folderId) return;
    if (!open && firstCall) return;
    setFirstCall(true);
    try {
      setLoading(true);
      const data = await getInvoicesTotalByFolderId(folderId);
      setInvoices(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [folderId, open, firstCall]);

  const totalTTC = useMemo(() => sumTotalPrices(invoices), [invoices]);
  const totalHt = useMemo(() => sumTotalPrices(invoices, 'totalHt'), [invoices]);

  return {
    onOpenInvoiceDetails: () => setOpen(!open),
    isInvoiceDetailsOpen: open,
    isInvoiceLoading: loading,
    invoices,
    totalTTC,
    totalHt,
    totalDocument,
  };
};

export default useInvoiceDetails;
