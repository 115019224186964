import { secureGet, securePost } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getProductComments = async (productId) => {
  const { data } = await securePost(`/ci/api/GetProductComments?product_id=${productId}`);
  return data;
};

export const getProductDetails = async (filters) => {
  let url = '/ci/api/GetProductsDetails';
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce(
          (prev, curr) => `${prev}${!url?.includes('?') && !prev ? '?' : '&'}${key}=${curr}`,
          '',
        )
        : `${url?.includes('?') ? '&' : '?'}${key}=${filters[key]}`;
    }
  });
  const { data } = await securePost(url);
  return data;
};

export const searchSuppliers = async (search) => {
  const { data } = await secureGet(
    `/ci/api/product/suppliers?search=${search}`,
  );
  return data;
};

export const addProductComment = async (productId, parentId, content) => {
  const { data } = await securePost(
    `/ci/api/AddComment?product_id=${productId}&parent_id=${parentId}&content=${content}`,
  );
  return data;
};
export const transferProduct = async (values) => {
  const { data } = await securePost(
    `/ci/api/RequestProductTransfer?product_id=${values.reference}&source=${values.source}&destination=${values.destination}&comment=${values.comment}&quantity=${values.quantity}`,
  );
  return data;
};

export const requestProduct = async (values) => {
  const { data } = await securePost('/ci/api/requestProduct', values);
  return data;
};

export const deleteProductComments = async (commentId) => {
  const { data } = await securePost(`/ci/api/DeleteComment?id=${commentId}`);
  return data;
};
