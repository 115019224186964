import {
  Box,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useCallback, useEffect, useState } from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import useForm from 'Shared/Hooks/hooks/useForm';
import axios from 'axios';
import { getOrders, sentToDecompta, sentToTransfo } from 'Components/ControleOrders/RecoveryServices';
import { defaultMessage } from 'Components/Orders/OrdersService';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { format } from 'date-fns';
import ButtonApp from 'Shared/Button';
import useMultiCheck from 'Shared/Hooks/useMultiCheck';
import { columns } from './constants';
import { SOUCHES, SOUCHES_EXTRA } from '../constants';

let cancelToken;
const TransformationBL = ({ handleClose, isDecompatalization = false }) => {
  const { values, onChange } = useForm({ type: '', souche: null, transfoDate: null });
  const [resetSort, setResetSort] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = React.useState({ content: [] });
  const {
    isAll, unselected, onCheckAll, onRest, selectedLines, onSelectOneLine,
  } = useMultiCheck();

  useEffect(() => {
    onRest();
  }, [values.startDate, values.endDate, values.numCommande, values.souche]);

  const handDateChange = (name) => (value) => {
    onChange({ target: { name, value } });
    if (name !== 'type') {
      setResetSort((prev) => !prev);
    }
  };

  const getOrderList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        if (typeof cancelToken !== typeof undefined) {
          cancelToken.cancel('Cancel');
        }
        cancelToken = axios.CancelToken.source();
        const data = await getOrders(
          sort,
          {
            type: isDecompatalization ? 7 : 3,
            startDate: values.startDate
              ? format(
                values.startDate instanceof Date
                  ? values.startDate
                  : new Date(values.startDate),
                'yyyy-MM-dd',
              )
              : undefined,
            endDate: values.endDate
              ? format(
                values.endDate instanceof Date
                  ? values.endDate
                  : new Date(values.endDate),
                'yyyy-MM-dd',
              )
              : undefined,
            numCommande: values.numCommande,
            souche: values.souche,
          },
          { cancelToken: cancelToken.token },
        );
        setOrders(data);
      } catch (e) {
        if (e.message !== 'Cancel') {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        }
      } finally {
        setLoading(false);
      }
    },
    [values.startDate, values.endDate, values.numCommande, values.souche],
  );
  const onChangeType = (event) => {
    onChange({ target: { name: 'type', value: event.target.value } });
    onChange({ target: { name: 'numCommande', value: null } });
    onChange({ target: { name: 'souche', value: '' } });
  };
  const onChangeValue = (event) => {
    onChange({ target: { name: event.target.name, value: event.target.value } });
    if (event.target.name !== 'type') {
      setResetSort((prev) => !prev);
    }
  };
  const onHandleDecompta = async () => {
    try {
      setLoading(true);
      await sentToDecompta(selectedLines, isAll, unselected, values, orders?.totalElements);
      handleClose();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const onHandleTransfo = async () => {
    try {
      setLoading(true);
      await sentToTransfo(selectedLines, isAll, unselected, values, orders?.totalElements);
      handleClose();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog
        open
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h5" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              {isDecompatalization ? 'Décomptabliser Documents' : 'Transformer le bon livraison'}
            </Typography>
            <IconButton>
              <CloseIcon onClick={handleClose} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
            <FormControl variant="outlined" size="small" style={{ marginRight: 10, width: 180 }}>
              {!values.type && <InputLabel id="demo-simple-select-label">Type de recherche </InputLabel>}
              <Select sx={{ '& fieldset': { border: 'none' }, backgroundColor: '#F7F7F7' }} name="type" value={values.type} label="Type de Recherche" onChange={onChangeType}>
                <MenuItem value="PIECE">
                  Par pièce
                </MenuItem>
                <MenuItem value="SOUCHE">
                  Par souche
                </MenuItem>
              </Select>
            </FormControl>
            {values.type !== 'SOUCHE' && (
            <FormControl
              disabled={!values.type}
              variant="outlined"
              size="small"
              sx={{ flexGrow: 1, '& fieldset': { border: 'none' }, backgroundColor: '#F7F7F7' }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                {values.type === 'PIECE' ? 'Recherche par pièce' : 'Recherche par souche'}
              </InputLabel>
              <OutlinedInput
                type="text"
                name="numCommande"
                value={values.numCommande}
                onChange={onChangeValue}
              />
            </FormControl>
            )}
            {values.type === 'SOUCHE' && (
            <FormControl variant="outlined" size="small" fullWidth>
              {!values.souche && <InputLabel id="demo-simple-select-label">Souche </InputLabel>}
              <Select sx={{ '& fieldset': { border: 'none' }, backgroundColor: '#F7F7F7' }} name="souche" value={values.souche} label="Type de Recherche" onChange={onChangeValue}>
                {(isDecompatalization ? SOUCHES_EXTRA : SOUCHES).map((s) => (
                  <MenuItem value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            )}
          </Box>
          <Box sx={{
            display: 'flex', alignItems: 'center', py: 2, columnGap: 2,
          }}
          >
            <DatePicker
              size="small"
              label="Date début"
              sx={{
                flexGrow: 1,
                borderRadius: '12px',
                '& fieldset': { border: 'none !important' },
              }}
              value={values.startDate}
              maxDate={values.endDate}
              slotProps={{
                field: { clearable: true },
                textField: {
                  style: { maxWidth: '100%', border: 'none !important', backgroundColor: '#F7F7F7' },
                  size: 'small',
                  InputProps: {
                    style: {
                      '& fieldset': { border: 'none !important' },
                    },
                  },
                },
              }}
              name="startDate"
              format="dd/MM/yyyy"
              onChange={handDateChange('startDate')}
              views={['year', 'month', 'day']}
            />
            <DatePicker
              size="small"
              label="Date fin"
              sx={{
                flexGrow: 1,
                borderRadius: '12px',
                '& fieldset': { border: 'none !important' },
              }}
              value={values.endDate}
              minDate={values.startDate}
              slotProps={{
                field: { clearable: true },
                textField: {
                  style: { maxWidth: '100%', border: 'none !important', backgroundColor: '#F7F7F7' },
                  size: 'small',
                  InputProps: {
                    style: {
                      '& fieldset': { border: 'none !important' },
                    },
                  },
                },
              }}
              name="endDate"
              format="dd/MM/yyyy"
              onChange={handDateChange('endDate')}
              views={['year', 'month', 'day']}
            />
          </Box>
          <EnhancedTable
            resetSort={resetSort}
            getData={getOrderList}
            rows={orders.content}
            headCells={columns(onSelectOneLine, onCheckAll, selectedLines, unselected, isAll)}
            count={orders?.totalElements || 0}
            rowsPerPageOptions={[5, 15, 20]}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          {isDecompatalization ? (
            <ButtonApp
              onClick={onHandleDecompta}
              label={`Décomptabliser La sélection (${isAll ? ((orders?.totalElements || 0) - unselected.length) : selectedLines.length})`}
              disabled={isAll
                ? ((orders?.totalElements || 0) - unselected.length) <= 0
                : selectedLines.length === 0}
              style={{ height: 40 }}
            />
          ) : (
            <>
              <DatePicker
                size="small"
                label="Date de transformation"
                sx={{
                  // flexGrow: 1,
                  borderRadius: '12px',
                  '& fieldset': { border: 'none !important' },
                }}
                value={values.transfoDate}
                slotProps={{
                  field: { clearable: true },
                  textField: {
                    style: { maxWidth: '100%', border: 'none !important', backgroundColor: '#F7F7F7' },
                    size: 'small',
                    InputProps: {
                      style: {
                        '& fieldset': { border: 'none !important' },
                      },
                    },
                  },
                }}
                name="transfoDate"
                format="dd/MM/yyyy"
                onChange={handDateChange('transfoDate')}
                views={['year', 'month', 'day']}
              />
              <ButtonApp
                onClick={onHandleTransfo}
                label={`Transformer La sélection (${isAll ? ((orders?.totalElements || 0) - unselected.length) : selectedLines.length})`}
                disabled={!values.transfoDate || (isAll
                  ? ((orders?.totalElements || 0) - unselected.length) <= 0
                  : selectedLines.length === 0)}
                style={{ height: 40 }}
              />
            </>
          )}
        </DialogActions>
      </Dialog>
    </>

  );
};

export default TransformationBL;
