/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useHistory } from 'react-router-dom';
import UserContext from 'Shared/UserContext';
import { resetLogin } from 'Shared/UserContext/Actions';
import {
  Badge,
  Box,
  CardMedia,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Autocomplete,
} from '@mui/material';
import ReferencialContext, {
  setSelectedBrand,
  setSelectedFamily,
  setSelectedProduct,
} from 'Shared/ReferencialContext';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DEFAULT_PRODUCT from 'Images/default_product.png';
import NotificationsIcon from 'Images/notification.svg';
import { defaultMessage } from 'Shared/Referencial/ReferencialService';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import SearchIcon from '@mui/icons-material/Search';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import { useDispatch } from 'react-redux';
import { setOpenTrace, setuserRole } from 'Components/Traceability/traceabilitySlice';
import Alert from './alert.png';
import ListboxComponent from './AdapaterReactWindow';
import { getNotifications, MarkAllNotificationsRead } from './HeaderService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  AppBar: {
    backgroundColor: 'transparent',
    background: 'transparent',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    lineHeight: 14,
  },
  media: {
    width: 103,
    height: 60,
  },
  cube: {
    width: 24,
    height: 22,
  },
  order: {
    // background: 'rgba(255, 255, 255, 0.11)',
    width: 164,
    height: 62,
    cursor: 'pointer',
  },
  profile: {
    fontSize: 12,
    lineHeight: '138 %',
    letterSpacing: '0.0075em',
    color: '#B3E5FB',
  },
  searchInput: {
    background: '#FFFFFF',
  },
  notched: {
    border: 'none',
  },
  badge: {
    backgroundColor: '#00B894',
    color: '#FFF',
    padding: 0,
  },
  label: {
    font: 'normal normal 600 12px Montserrat',
    color: '#AFB0B2',
  },
}));
const mapRole = (role) => {
  switch (role) {
    case 'ROLE_DISPATCHER':
      return 'dispatcher';
    case 'ROLE_ADMIN':
      return 'admin';
    case 'ROLE_LIAISON':
      return 'liaison';
    case 'BUYER':
      return 'Achat';
    default:
      return role;
  }
};
const RenderOption = ({ option, ...props }) => {
  const classes = useStyles();
  return (
    <Box sx={{ display: 'flex' }} {...props}>
      <div style={{ height: 60 }}>
        <img
          // eslint-disable-next-line no-return-assign
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = DEFAULT_PRODUCT;
          }}
          className={classes.media}
          src={`https://media.copima.ma/${
            option?.img
              ? `products/${option?.img}`
              : option?.familyPicture
                ? `families/${option?.familyPicture}`
                : `brands/${option?.logoImage}`
          }`}
          alt={option?.fId || option?.name || option?.brand}
        />
      </div>
      <Tooltip
        title={
          option.reference
            ? `Produit: ${option.reference} ${option?.des}`
            : false || (option.familyLevel ? 'Famille :' : 'Marque :') + option.name
        }
      >
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', height: 20 }}>
          {option.reference
            ? `Produit: ${option.reference} ${option?.des}`
            : false || (option.familyLevel ? 'Famille :' : 'Marque :') + option.name}
        </span>
      </Tooltip>
    </Box>
  );
};
const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatchSlice = useDispatch();
  const {
    dispatch: dispatchRef,
    state: { referencial },
  } = useContext(ReferencialContext);
  const [catalog, setCatalog] = useState([]);
  const [search, setSearch] = useState();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = async () => {
    setOpen((prevOpen) => !prevOpen);
    if (!open) {
      try {
        await MarkAllNotificationsRead();
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      }
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const {
    dispatch,
    state: { user },
  } = useContext(UserContext);
  const handleInputChange = (event, value) => {
    setSearch(value);
    if (value) {
      if (value.reference) {
        dispatchRef(setSelectedProduct(value));
      } else if (value.familyLevel) {
        dispatchRef(setSelectedFamily(value));
        history.push('/catalog');
      } else {
        dispatchRef(setSelectedBrand(value));
        history.push('/catalog');
      }
    }
  };
  const logout = () => {
    dispatch(resetLogin());
    history.push('/login');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };
  const getNotificationList = useCallback(async () => {
    try {
      setLoading(false);
      const data = await getNotifications();
      setNotifications(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    getNotificationList();
  }, []);
  useEffect(() => {
    const { products = [], families = [], brands = [] } = referencial;
    setCatalog([
      ...products.filter((p) => p.iA),
      ...families,
      ...brands.filter((brand) => products.map((po) => po.bId).includes(brand.id)),
    ]);
  }, [referencial, setCatalog]);
  const handleClick = (clientCode) => (event) => {
    dispatchRef(setSelectedProduct(clientCode));
    handleClose(event);
  };
  const onOpenTraceability = () => {
    dispatchSlice(setOpenTrace());
    dispatchSlice(setuserRole(user.role));
  };
  return (
    <div className={classes.root}>
      {loading && <GlobalLoader size="100vh" />}
      <Box position="static" elevation={0} className={classes.AppBar}>
        <Toolbar className={classes.AppBar}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              columnGap: 20,
            }}
          >
            <Box
              style={{
                display: 'flex',
                backgroundColor: '#FFF',
                alignItems: 'center',
                flexGrow: 1,
                flexShrink: 4,
                paddingLeft: 8,
                borderRadius: 12,
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 12px 26px #0000000D',
              }}
            >
              <SearchIcon style={{ color: 'gray' }} />
              <Autocomplete
                options={catalog}
                classes={{
                  option: classes.option,
                  inputRoot: classes.searchInput,
                }}
                onChange={(a, value) => handleInputChange(a, value)}
                value={search}
                size="small"
                fullWidth
                autoHighlight
                getOptionLabel={(option) => option.reference || option.name}
                ListboxComponent={ListboxComponent}
                renderOption={(props, option) => <RenderOption {...props} option={option} />}
                filterOptions={(options, v) => options
                  .filter((option) => {
                    if (option.hasOwnProperty('reference')) {
                      return (
                        option.reference?.toLowerCase().includes(v.inputValue.toLowerCase())
                          || option.des?.toLowerCase().includes(v.inputValue.toLowerCase())
                          || option.e?.toLowerCase().includes(v.inputValue.toLowerCase())
                          || option.rO?.toLowerCase().includes(v.inputValue.toLowerCase())
                          || option.fId?.toLowerCase().includes(v.inputValue.toLowerCase())
                          || option.bId?.toLowerCase().includes(v.inputValue.toLowerCase())
                      );
                    }
                    return option.name?.toLowerCase().includes(v.inputValue.toLowerCase());
                  })
                  .slice(0, 5000)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Chercher"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      ...params.InputProps,
                      classes: { notchedOutline: classes.notched },
                    }}
                    InputLabelProps={{
                      classes: { root: classes.label },
                    }}
                  />
                )}
              />
            </Box>

            {['IMPORTER', 'BUYER', 'SAV'].includes(user.role) ? (
              <IconButton
                color="primary"
                aria-label="Traçabilités"
                style={{
                  background: '#FFFFFF 0% 0% no-repeat padding-box',
                  boxShadow: '0 12px 26px #0000000D',
                  borderRadius: 12,
                  opacity: 1,
                }}
                onClick={onOpenTraceability}
              >
                <Tooltip title="Traçabilités">
                  <DifferenceOutlinedIcon color="#007BC6" />
                </Tooltip>
              </IconButton>
            ) : null }
            <IconButton
              color="primary"
              aria-label="notification"
              component="span"
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              style={{
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0 12px 26px #0000000D',
                borderRadius: 12,
                opacity: 1,
              }}
              size="large"
            >
              <Badge
                badgeContent={notifications.filter((n) => !n?.status).length}
                classes={{ badge: classes.badge }}
              >
                <CardMedia
                  component="img"
                  src={NotificationsIcon}
                  style={{ width: 18, height: 20 }}
                />
              </Badge>
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              placement="bottom-start"
              style={{ zIndex: 100, height: 100, width: 400 }}
            >
              {({ TransitionProps, placement }) => (
                <Box
                  style={{
                    zIndex: 100,
                    position: 'absolute',
                    width: 400,
                    left: '-194px',
                    top: '20px',
                    transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
                    overflow: 'visible',
                  }}
                >
                  <Box style={{ position: 'relative' }}>
                    <Box
                      style={{
                        position: 'absolute',
                        width: 0,
                        height: 0,
                        borderLeft: '15px solid transparent',
                        borderRight: '15px solid transparent',
                        borderBottom: '21px solid white',
                        top: '-20px',
                        right: '20px',
                        zIndex: 999,
                      }}
                    />
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
                        height: 500,
                        overflowY: 'auto',
                      }}
                    >
                      <Paper style={{ padding: '30px 20px 15px' }}>
                        <Typography
                          style={{
                            textAlign: 'left',
                            font: 'normal normal 600 24px/29px Montserrat',
                            letterSpacing: 0,
                            color: '#000000',
                            opacity: 1,
                          }}
                        >
                          Notifications
                        </Typography>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {notifications.map((n) => (
                              <MenuItem
                                key={n.notif_id}
                                onClick={handleClick(n.type_id)}
                                style={{
                                  height: 80,
                                  // background: n.status ? '#FFFFFF' : '#FA8072',
                                  // color: n.status ? '#000000' : '#FFFFFF',
                                  opacity: n.status ? 0.5 : 1,
                                  alignItems: 'flex-start',
                                  columnGap: 5,
                                }}
                              >
                                <img src={Alert} alt="alert" />
                                <Box style={{ width: '100%' }}>
                                  <Box style={{ display: 'flex', width: '100%' }}>
                                    <Typography
                                      style={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'start',
                                        font: 'normal normal 600 12px/15px Montserrat',
                                        letterSpacing: 0,
                                        color: '#707070',
                                        flexGrow: 1,
                                      }}
                                      variant="subtitle2"
                                    >
                                      {n.created_on}
                                    </Typography>
                                    {!n.status && (
                                      <FiberManualRecordIcon
                                        size="small"
                                        style={{ color: '#007BC6', fontSize: 15 }}
                                      />
                                    )}
                                  </Box>
                                  <Box
                                    style={{
                                      whiteSpace: 'pre-line',
                                      letterSpacing: 0,
                                      color: '#38485C',
                                      font: 'normal normal 500 16px/22px Montserrat',
                                    }}
                                    variant="subtitle1"
                                  >
                                    {n.message}
                                  </Box>
                                </Box>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  </Box>
                </Box>
              )}
            </Popper>
            <Divider orientation="vertical" style={{ height: 30 }} />
            <Box style={{ display: 'flex', columnGap: 20, alignItems: 'center' }}>
              <Box
                style={{
                  background: '#007BC6 0% 0% no-repeat padding-box',
                  opacity: 1,
                  height: 52,
                  width: 52,
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                }}
              >
                <PersonOutlineIcon style={{ color: '#FFF' }} />
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
                <Typography
                  style={{
                    font: 'normal normal 400 18px Montserrat',
                    color: '#000000',
                    opacity: 1,
                    textTransform: 'capitalize',
                  }}
                >
                  {user.username?.toLowerCase()}
                </Typography>
                <Typography
                  style={{
                    font: 'normal normal 400 14px Montserrat',
                    color: '#007BC6',
                    opacity: 1,
                    textTransform: 'capitalize',
                  }}
                >
                  {`${mapRole(user.role)?.toLowerCase()} - ${
                    user?.affectation === 'Bouskoura' ? 'BO' : 'DO'
                  }`}
                </Typography>
              </Box>
            </Box>
            {false && (
              <Grid item xs={4} container direction="row-reverse">
                <div style={{ width: '100%', height: 62 }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    alignContent="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item style={{ margin: '0 15px' }}>
                      <AccountCircle />
                    </Grid>
                    <Grid item style={{ margin: '0 15px' }}>
                      <Typography variant="body1">{user.username}</Typography>
                      <Typography variant="body2" className={classes.profile}>
                        {`${mapRole(user.role)}`}
                      </Typography>
                    </Grid>
                    <Grid item style={{ margin: '0 15px' }}>
                      <Typography variant="body1" display="block">
                        {user.affectation}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton color="inherit" onClick={logout} style={{ padding: 0 }} size="large">
                        <ExitToAppIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
          </Box>
        </Toolbar>
      </Box>
    </div>
  );
};

Header.propTypes = {};

export default Header;
