import * as React from 'react';
import { Typography, Switch, Box } from '@mui/material';
import { styled } from '@mui/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#007BC6',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CustomizedSwitch = ({
  name, onChange, checked = false, disabled = false,
}) => (
  <Box style={{ display: 'flex', flexDirection: 'row' }}>
    <AntSwitch checked={checked} name={name} onChange={onChange} disabled={disabled} inputProps={{ 'aria-label': 'ant design' }} />
    <Typography style={{
      color: (checked ? '#007BC6' : '#38485C'),
      font: 'normal normal  11px Montserrat',
      fontWeight: 'bold',
      marginLeft: '8px',
    }}
    >
      {checked ? 'OUI' : 'NON' }
    </Typography>
  </Box>
);

export default CustomizedSwitch;
