import {
  Box, CardMedia, Chip, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import OptionIcon from 'Images/option.svg';
import FolderIcon from '@mui/icons-material/Folder';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import SendIcon from '@mui/icons-material/Send';
import ArchiveIcon from '@mui/icons-material/Archive';

export const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 3px',
};
export const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
  display: 'block',
};

export const TYPES_COMMANDES = [
  { id: 1, label: 'BC' },
  { id: 3, label: 'BL' },
  { id: 4, label: 'BR' },
  { id: 6, label: 'FA' },
  { id: 7, label: 'FC' },
  { id: 11, label: 'PL' },
  { id: 13, label: 'BL' },
  { id: 14, label: 'BR' },
  { id: 16, label: 'FA' },
  { id: 17, label: 'FC' },
];

export const ImportStatus = ({ icon, label, bgColor }) => (
  <Chip
    avatar={icon}
    label={label}
    variant="outlined"
    style={{
      height: '25px',
      backgroundColor: bgColor,
      borderRadius: '24px',
      color: '#FFF',
      border: 'none',
    }}
  />
);

export const getFolderStatus = (status) => {
  switch (status) {
    case 'PREPARED':
      return (
        <ImportStatus
          icon={<FolderIcon style={{ color: '#FFF', width: '20px' }} />}
          label="PRÉPARÉ"
          bgColor="#65C979"
        />
      );
    case 'VENTILATED':
      return (
        <ImportStatus
          icon={<ThumbsUpDownIcon style={{ color: '#FFF', width: '20px' }} />}
          label="VENTILÉ"
          bgColor="#00CEC9"
        />
      );
    case 'calculé':
      return (
        <ImportStatus
          icon={<KeyboardIcon style={{ color: '#FFF', width: '20px' }} />}
          label="CALCULÉ"
          bgColor="#74B9FF"
        />
      );
    case 'lancé':
      return (
        <ImportStatus
          icon={<SendIcon style={{ color: '#FFF', width: '20px' }} />}
          label="LANCÉ"
          bgColor="#007BC6"
        />
      );
    case 'archivé':
      return (
        <ImportStatus
          icon={<ArchiveIcon style={{ color: '#FFF', width: '20px' }} />}
          label="ARCHIVÉ"
          bgColor="#38485C"
        />
      );

    default:
      return (
        <ImportStatus
          icon={<FolderIcon style={{ color: '#FFF', width: '20px' }} />}
          label="PRÉPARÉ"
          bgColor="#65C979"
        />
      );
  }
};

export const columns = [
  {
    id: 'folderCode',
    label: 'N° DOCUMENT',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
    order: 'folderCode',
  },
  {
    id: 'type',
    label: 'TYPE',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value && TYPES_COMMANDES.find((t) => t.id === value)?.label}
      </Typography>
    ),
    order: 'type',
  },
  {
    id: 'fournisseur',
    label: 'INTITULÉ FOURNISSEURS:',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {value}
      </Typography>
    ),
    order: 'supplier',
  },
  {
    id: 'date',
    label: 'DATE',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {value && format(new Date(value), 'dd/MM/yyy')}
      </Typography>
    ),
    order: 'date',
  },
  {
    id: 'arrivalDate',
    label: 'DATE ARRIVÉE',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {value && format(new Date(value), 'dd/MM/yyyy')}
      </Typography>
    ),
  },
  {
    id: 'statut',
    label: 'Statut',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => getFolderStatus(value),
    order: 'status',
  },
  {
    id: 'montantRegle',
    label: 'MONTANT',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography
        style={{
          ...defaultColumnStyle,
          mr: '9px',
          fontWeight: 'bold',
          color: '#007BC6',
          textTransform: 'uppercase',
        }}
        display="inline"
      >
        {value}
        {' '}
        DH
      </Typography>
    ),
    order: 'montantRegle',
  },
  {
    id: 'action',
    label: '',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ row }) => (
      <Box
        onClick={row.onOpenActions(row)}
        style={{
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 30,
          width: 30,
          padding: 5,
          cursor: 'pointer',
        }}
      >
        <CardMedia component="img" src={OptionIcon} style={{ width: 20 }} />
      </Box>
    ),
  },
];

export const TYPES_ACHAT_CODE = {
  BC: 1,
  PL: 11,
  BL: 13,
  BR: 14,
  FA: 16,
  FC: 17,
};

export const TYPES_ACHAT = [
  { id: 1, name: 'BC' },
  { id: 11, name: 'PL' },
  { id: 13, name: 'BL' },
  { id: 14, name: 'BR' },
  { id: 16, name: 'FA' },
  { id: 17, name: 'FC' },
];

export const inputsA = [
  { name: 'droitsDouane', label: 'DROITS DOUANE REELS' },
  { name: 'pesage', label: 'Pesage' },
  { name: 'interestObligation', label: 'Interest Obligation' },
  { name: 'magasinage', label: 'Magasinage' },
  { name: 'entrepot', label: 'Entrepot' },
  { name: 'testLab', label: 'Test Labo' },
];

export const inputsB = [
  { name: 'surstaris', label: 'Surstaris' },
  { name: 'autresCharges', label: 'Autres Charges' },
  { name: 'comission', label: 'Comission Et Frais B…' },
  { name: 'controleCertificat', label: 'Controle Certifica…' },
  { name: 'redevanceInfo', label: 'Redevance Inform…' },
  { name: 'coutControle', label: 'Cout Controle Con…' },
];

export const DEPOSITS = [{ id: 1, name: 'Bouskoura' }, { id: 2, name: 'Derb Omar' }];

export const INCOTERMS = [
  'CFR', 'CIF', 'CIP', 'CPT', 'DAP', 'DAT', 'DDP', 'EXW', 'FAS', 'FCA', 'FOB',
];
