/* eslint-disable max-len */
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import React, {
  useContext, useState, useMemo, useRef, useCallback, useEffect,
} from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import useForm from 'Shared/Hooks/useForm';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Checkbox,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Typography,
  Box,
  TextField,
  Popper,
  Autocomplete,
  IconButton,
} from '@mui/material';
import ButtonApp from 'Shared/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import { toast } from 'react-toastify';
import DialogCmp from 'Shared/Dialog';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import useStyles from './style';
import NewArticle from './NewArticle/NewArticle';
import {
  UploadOptions, columns, mapApiById, INIT_ARTICLE_FORM,
} from './constants';
import DetailProduct from './Detail';
import ActionsProduct from './Detail/ActionsProduct';
import {
  addArticle, defaultMessage, deleteProduct, getHeaderById, getRefSuppliers,
} from './importServices';

const ColumnList = ({ classes, onChangeColumns, selectedColumns }) => {
  const onCheck = (value) => () => {
    onChangeColumns(value);
  };
  const items = columns();
  return (
    <div className={classes.selectItems}>
      {items.slice(2, items.length).map((column) => (
        <MenuItem key={column.id} onClick={onCheck(column.id)}>
          <Checkbox
            checked={selectedColumns.indexOf(column.id) > -1}
            style={{ color: '#007BC6' }}
          />
          <ListItemText primary={column.label} className={classes.selectItem} />
        </MenuItem>
      ))}
    </div>
  );
};

const SimpleColumnList = ({
  classes, items, onClick, getTemplate,
}) => (
  <div className={classes.items}>
    {items?.map((column) => (
      <MenuItem key={column.id} onClick={() => onClick(column.id)}>
        <ListItemText primary={column.label} className={classes.selectItem} />
        <ListItemIcon>
          <IconButton onClick={(event) => getTemplate(event, column.id, column.label)}>
            <InsertDriveFileIcon fontSize="small" sx={{ color: '#007BC6' }} />
          </IconButton>
        </ListItemIcon>
      </MenuItem>
    ))}
  </div>
);
const PopperMy = (props) => <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;

const Import = () => {
  const {
    state: { referencial },
  } = useContext(ReferencialContext);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openSuccessArticle, setOpenSuccessArticle] = useState(false);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadId, setUploadId] = useState();
  const [openProduct, setOpenProduct] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});

  const classes = useStyles();
  const { values: newArticleForm, onChange: onChangeForm, reset } = useForm({ ...INIT_ARTICLE_FORM });
  const ref = useRef();
  const refx = useRef();
  const { values, onChange } = useForm({
    page: 0,
    size: 10,
    search: '',
    family: [],
    displayedColumns: ['reference', 'designation', 'nomenclature', 'stock', 'priceAch', 'bId', 'action'],
    customColumns: false,
  });

  const products = referencial.products
    ?.filter(
      (p) => (!values.search || p.reference.includes(values.search))
        && (!values.family.length || values.family.map((f) => f.id).includes(p.fId)),
    )
    ?.slice(values.page * values.size, values.page * values.size + values.size) || [];

  const families4 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 4);
  }, [referencial.families]);

  useEffect(async () => {
    if (newArticleForm.productId) {
      const suppliers = await getRefSuppliers(newArticleForm.productId);
      onChangeForm({
        target: {
          name: 'suppliers',
          value: suppliers.map((supplier) => ({
            ...supplier,
            name: supplier.supplierName,
            referenceFournisseurs: {
              reference: supplier.reference,
              prix: supplier.prix,
              devise: supplier.devise,
            },
          })),
        },
      });
    }
  }, [
    newArticleForm.productId,
  ]);

  const onChangeSize = (size) => {
    onChange({ target: { name: 'size', value: size } });
  };
  const onChangePage = (page) => {
    onChange({ target: { name: 'page', value: page } });
  };
  const onHandleCustomColumns = () => {
    onChange({ target: { name: 'customColumns', value: !values.customColumns } });
  };
  const onCloseCustomColumns = () => {
    onChange({ target: { name: 'customColumns', value: false } });
  };
  useOnClickOutside(ref, onCloseCustomColumns);
  useOnClickOutside(refx, () => setOpen(false));
  const onChangeColumns = (checkedValue) => {
    const filtredList = values.displayedColumns.filter((item) => checkedValue !== item);
    const newList = filtredList.length !== values.displayedColumns.length
      ? filtredList
      : [...values.displayedColumns, checkedValue];

    onChange({
      target: {
        name: 'displayedColumns',
        value: newList,
      },
    });
  };
  const onOpenActions = (clickedProduct) => (event) => {
    setOpenProduct(!openProduct);
    setAnchorEl(event.currentTarget);
    if (!openProduct) {
      setSelectedProduct(clickedProduct);
    }
  };

  const tableColumns = useMemo(
    () => columns(onOpenActions, referencial.families).filter((item) => values.displayedColumns.indexOf(item.id) > -1),
    [values.displayedColumns],
  );
  const handleChangeInput = (event, value) => {
    onChange(value ? { target: { name: 'family', value } } : event);
  };
  const onHandleAdd = () => {
    // onChangeForm({ target: { name: 'isOpen', value:  } });
    reset({ ...INIT_ARTICLE_FORM, isOpen: !newArticleForm.isOpen });
  };
  const fileInputRef = useRef(null);

  const handleUpload = (id) => {
    setUploadId(id);
    setOpen(false);
    fileInputRef.current.click();
  };

  const downloadTemplate = useCallback(
    async (event, id, label) => {
      event.stopPropagation();
      try {
        setLoading(true);
        await getHeaderById(id, label);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const loadData = useCallback(
    async (file, functions, event) => {
      setLoading(true);
      try {
        const data = await functions(file);
        if (data?.statusCode) {
          toast.error(data?.statusLabel);
        } else {
          setOpenSuccess(true);
        }
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
        setUploadId(undefined);
        // eslint-disable-next-line no-param-reassign
        event.target.value = null;
      }
    },
    [setLoading, setUploadId],
  );
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const ApiCall = mapApiById(uploadId);
    if (ApiCall && file) {
      loadData(file, ApiCall, event);
    }
  };
  const onSubmitArticle = async () => {
    try {
      setLoading(true);
      await addArticle(newArticleForm);
      onHandleAdd();
      setOpenSuccessArticle(true);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const onHandleDetail = () => {
    setOpenDetail(!openDetail);
  };
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await deleteProduct(selectedProduct.productId);
      setOpenSuccessDelete(true);
    } catch (error) {
      setOpenSuccessDelete(false);
    } finally {
      setLoading(false);
    }
  }, [setOpenSuccessArticle, setLoading, selectedProduct]);
  const onHandleEdit = () => {
    reset({
      ...selectedProduct,
      id: selectedProduct.productId,
      huile: selectedProduct.iH,
      price: selectedProduct.p,
      fId: { id: selectedProduct.fId, name: referencial.families?.find((f) => f.id === selectedProduct.fId)?.name },
      bId: { id: selectedProduct.bId, name: selectedProduct.bId },
      suppliers: [],
      isOpen: !newArticleForm.isOpen,
    });
  };
  return (
    <div style={{ height: '100vh' }}>
      {loading && <GlobalLoader size="100vh" />}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
        onHandleSubmit={() => setOpenSuccess(false)}
        labelKo=""
        labelOk="Ok"
        actionStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Box
          style={{
            margin: '0 20px',
            marginTop: '-34px',
          }}
        >
          <Box className={classes.successCheck}>
            <CheckIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography className={classes.successMsg}>
            La liste des produits sélectionnés a été bien chargée
          </Typography>
        </Box>
      </DialogCmp>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.title}>List des Articles</Typography>
          <div className={classes.selectContainer} ref={ref}>
            <ButtonApp
              label="Modifier les Champs"
              startIcon={<EditOutlinedIcon />}
              endIcon={<ExpandMoreIcon />}
              onClick={onHandleCustomColumns}
              style={{ color: '#007BC6', background: '#FFF', border: '1px solid #007BC6' }}
            />
            {values.customColumns && (
              <ColumnList
                classes={classes}
                selectedColumns={values.displayedColumns}
                onChangeColumns={onChangeColumns}
              />
            )}
          </div>
          <div className={classes.selectContainer} ref={refx}>
            <ButtonApp
              label="Importer..."
              startIcon={<EditOutlinedIcon />}
              endIcon={<ExpandMoreIcon />}
              onClick={() => setOpen((prev) => !prev)}
              style={{ color: '#007BC6', background: '#FFF', border: '1px solid #007BC6' }}
            />
            {open && (
              <SimpleColumnList classes={classes} items={UploadOptions} onClick={handleUpload} getTemplate={downloadTemplate} />
            )}
          </div>
          <ButtonApp label="Ajouter" endIcon={<AddIcon />} onClick={onHandleAdd} />
        </div>
        <Box className={classes.filterZone}>
          <TextField
            variant="outlined"
            onChange={handleChangeInput}
            value={values.search}
            label="chercher"
            size="small"
            name="search"
            className={classes.filter}
            style={{ width: 200 }}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={families4 || []}
            onChange={handleChangeInput}
            value={values.family}
            size="small"
            name="family"
            multiple
            style={{ minWidth: 200 }}
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            // eslint-disable-next-line max-len
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 1"
                variant="outlined"
                name="family"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        </Box>
        <EnhancedTable
          rows={products}
          headCells={tableColumns}
          count={referencial.products?.length || 0}
          rowsPerPageOptions={[10, 15, 20]}
          onChangeRowsPerPage={onChangeSize}
          onChangePage={onChangePage}
          containerClass={classes.table}
        />
        <DialogCmp
          title={newArticleForm.productId ? `${newArticleForm.reference} : ${newArticleForm.des}` : 'Nouvel Article'}
          endIcon={false}
          fullWidth
          maxWidth="lg"
          open={newArticleForm.isOpen}
          handleClose={onHandleAdd}
          onHandleSubmit={onSubmitArticle}
          labelOk="ENREGISTER"
          actionsTop
          styleOk={{ height: 30 }}
          styleKo={{ height: 30 }}
        >
          <NewArticle
            article={newArticleForm}
            families={families4}
            brands={referencial.brands}
            onChange={onChangeForm}
            isEditMode={!!newArticleForm.productId}
          />
        </DialogCmp>
        <DialogCmp
          title=""
          endIcon={false}
          fullWidth={false}
          open={openSuccessArticle}
          handleClose={() => setOpenSuccessArticle(false)}
          onHandleSubmit={() => setOpenSuccessArticle(false)}
          labelKo="Fermer"
          labelOk="Ok"
        >
          <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
            <Box className={classes.successCheck}>
              <CheckIcon fontSize="large" style={{ margin: 'auto' }} />
            </Box>
            <Typography className={classes.successMsg}>
              {`Votre Article ${newArticleForm.des || ''} a été bien ajouter`}
            </Typography>
          </Box>
        </DialogCmp>

        <DialogCmp
          title=""
          endIcon={false}
          fullWidth={false}
          open={openSuccessDelete === false}
          handleClose={() => setOpenSuccessDelete(null)}
          onHandleSubmit={() => setOpenSuccessDelete(null)}
          labelOk="Ok"
        >
          <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <Box sx={{
                backgroundColor: '#F86060',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '114px',
                height: '114px',
                borderRadius: '50%',
              }}
              >
                <BlockIcon fontSize="large" sx={{ color: '#fff' }} />
              </Box>
            </Box>
            <Typography className={classes.successMsg}>
              Vous ne pouvez pas supprimer ce produit, car il existe dans un document !
            </Typography>
          </Box>
        </DialogCmp>

        <DialogCmp
          title=""
          endIcon={false}
          fullWidth={false}
          open={openSuccessDelete === true}
          handleClose={() => setOpenSuccessDelete(null)}
          onHandleSubmit={() => setOpenSuccessDelete(null)}
          labelOk="Ok"
        >
          <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
            <Box className={classes.successCheck}>
              <CheckIcon fontSize="large" style={{ margin: 'auto' }} />
            </Box>
            <Typography className={classes.successMsg}>
              Produit supprimé avec succès !
            </Typography>
          </Box>
        </DialogCmp>
        <ActionsProduct
          onHandleDetail={onHandleDetail}
          onHandleEdit={onHandleEdit}
          onHandleDelete={handleDelete}
          onClose={onOpenActions}
          isOpen={openProduct}
          anchorEl={anchorEl}
        />
        <DetailProduct product={selectedProduct} openDetail={openDetail} onClose={onHandleDetail} />
      </div>
    </div>
  );
};

export default Import;
