import {
  baseURL,
  getToken, secureGet, securePost, securePut, secureDelete,
} from 'config/apiConfig';
import axios from 'axios';
import { format } from 'date-fns';
import * as XLSX from 'xlsx/xlsx.mjs';

export const defaultMessage = 'Erreur système';
export const importNewProducts = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const url = '/ci/api/product/load-new-products';
  const { data } = await securePost(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

export const importProductCustoms = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const url = '/ci/api/product/load-customs';
  const { data } = await securePost(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

export const importSuppliersReferences = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const url = '/ci/api/product/load-refs-suppliers';
  const { data } = await securePost(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

const toBlob = (obj) => {
  const json = JSON.stringify(obj);
  return new Blob([json], {
    type: 'application/json',
  });
};
export const addArticle = async (article) => {
  const { image, suppliers, ...product } = article;
  if (!image && !product.img) {
    throw new Error('Attacher l`image d`acticle !');
  }
  if (!product.des || !product.reference || !product.fId?.id) {
    throw new Error('Information A est obligatoire !');
  }
  if (!product.bId?.id) {
    throw new Error('La marque est obligatoire !');
  }
  const refSuppliers = suppliers.map(({ id, referenceFournisseurs }) => ({
    ...referenceFournisseurs,
    supplierId: id,
  }));
  const blobArticle = toBlob({
    ...product,
    p: product.price,
    fId: product.fId.id,
    bId: product.bId.id,
  });
  const blobRefSuppliers = toBlob(refSuppliers);
  const formData = new FormData();
  formData.append('image', image);
  formData.append('article', blobArticle);
  formData.append('suppliers', blobRefSuppliers);
  const url = '/ci/api/product/add';
  const urlUpdate = `/ci/api/product/update/${product.productId}`;

  const { data } = await securePost(product.productId ? urlUpdate : url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  if (data.statusCode === '400') {
    throw new Error(data.statusLabel);
  }
  return data;
};

export const getAllDocuments = async (sortParams, filters, conf) => {
  const sDate = filters.start ? `&start=${format(filters.start, 'yyyy-MM-dd')}` : '';
  const eDate = filters.end ? `&end=${format(filters.end, 'yyyy-MM-dd')}` : '';
  const xType = filters.type ? `&type=${filters.type.id}` : '';
  const xSearch = filters.search ? `&documentNumber=${filters.search}` : '';

  const url = `/ci/api/achat?page=${sortParams.page}&size=${sortParams.size}
  ${sDate}${eDate}${xType}${xSearch}${sortParams.sort ? `&sort=${sortParams.sort}` : ''}`;
  const { data } = await secureGet(url, conf);
  return data;
};

export const getAllDocumentDetails = async (sortParams, search, folderId) => {
  const sSearch = search ? `&search=${search}` : '';
  const sId = folderId ? `&folderId=${folderId}` : '';

  const url = `/ci/api/achat/details?page=${sortParams.page}&size=${sortParams.size}
  ${sSearch}${sId}`;
  const { data } = await secureGet(url);
  return data;
};

export const addDocumentDetail = async (folderId, achatDetail) => {
  const url = `/ci/api/achat/details/add/${folderId}`;
  const { data } = await securePost(url, { ...achatDetail, id: null });
  if (data.statusCode) {
    throw new Error(data.statusLabel);
  }
  return data;
};

export const updateDocumentDetails = async (achatDetailId, achatDetail) => {
  const url = `/ci/api/achat/details/${achatDetailId}`;
  const { data } = await securePut(url, achatDetail);
  return data;
};

export const getDocumentById = async (id) => {
  const { data } = await secureGet(`/ci/api/achat/${id}`);
  return data;
};

export const getFolderById = async (id) => {
  const { data } = await secureDelete(`/ci/api/achat/${id}`);
  return data;
};

export const getInvoicesTotalByFolderId = async (folderId) => {
  const { data } = await secureGet(`/ci/api/achat/${folderId}/invoices`);
  return data;
};

export const getHeaderById = async (id, label) => {
  const { data } = await secureGet(`/ci/api/product/import-header/${id}`);
  if (!data?.statusCode) {
    const wb = XLSX.utils.book_new();
    data.forEach((sheetResponse) => {
      const ws = XLSX.utils.aoa_to_sheet([sheetResponse.header]);

      XLSX.utils.book_append_sheet(wb, ws, `Sheet ${sheetResponse.sheet + 1}`);
    });

    const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${label}.xlsx`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }
};

export const getAllImportPaymentModes = async () => {
  const { data } = await secureGet('/ci/api/achat/import-payment-mode');
  return data;
};

export const saveDocument = async (document) => {
  const { data } = await securePut('/ci/api/achat', document);
  return data;
};

export const deleteAchatProduct = async (id) => {
  const { data } = await secureDelete(`/ci/api/achat/product?id=${id}`);
  return data;
};

export const deleteProduct = async (id) => {
  const { data } = await secureDelete(`/ci/api/product/${id}`);
  return data;
};

export const importDocumentAchatByFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const url = '/ci/api/achat/upload';
  const { data } = await securePost(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

export const convertTo = async (id, currentType, targetType) => {
  const { data } = await securePost(`/ci/api/achat/${id}/from/${currentType}/to/${targetType}`);

  return data;
};

export const getRefSuppliers = async (productId) => {
  const { data } = await secureGet(`/ci/api/product/${productId}/suppliers`);

  return data;
};

export const ventilation = async (folderId, invoicesFrais) => {
  const { data } = await securePost(`/ci/api/achat/${folderId}/ventilation`, invoicesFrais);
  return data;
};

export const costPrice = async (folderId) => {
  const { data } = await securePost(`/ci/api/achat/${folderId}/cost-price`);
  return data;
};

export const getCustomsFile = async (id, name = 'Fichier Douane') => {
  const token = await getToken();
  const { data } = await axios.get(`${baseURL}/ci/api/achat/export/${id}?token=${token}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {},
    responseType: 'arraybuffer',
  });
  const blolUrl = window.URL.createObjectURL(
    new Blob([data], { type: 'application/vnd.ms-excel' }),
  );
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = blolUrl;
  a.download = `${name}.xlsx`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(blolUrl);
  return data;
};

export const checkLastGeneratedFile = async (folderId) => {
  const { data } = await secureGet(`/ci/api/achat/export/${folderId}/last`);
  return data;
};

export const deleteFamily = async (familyId) => {
  const { data } = await secureDelete(`/ci/api/family/delete/${familyId}`);
  return data;
};
export const addFamily = async (family) => {
  const body = { ...family, familyLevel: 4, parentId: family.family1?.id };
  const { data } = await securePost('/ci/api/family/add', body);
  if (data.statusCode) {
    throw new Error(data.statusLabel);
  }
  return data;
};
export const updateFamily = async (family) => {
  const body = { ...family, parentId: family.family1?.id };
  const { data } = await securePut('/ci/api/family/update', body);
  if (data.statusCode) {
    throw new Error(data.statusLabel);
  }
  return data;
};
