import { makeStyles } from '@mui/styles';

export const useStylesArticle = makeStyles(() => ({
  box: {
    background: 'rgba(56, 72, 92, 0.11)',
    borderRadius: 12,
    padding: 20,
    margin: 7,
    position: 'relative',
  },
  boxTitle: {
    font: 'normal 600 16px Montserrat',
    color: '#38485C',
    opacity: 1,
  },
  boxHeader: { display: 'flex', justifyContent: 'space-between', marginBottom: '8px' },
  boxAction: { display: 'flex' },
}));

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 10px 20px 25px',
    overflowY: 'auto',
    height: 'calc(100vh - 104px)',
  },
  title: { font: 'normal 600 26px Montserrat', flexGrow: 1 },
  header: { display: 'flex', justifyContent: 'space-between', marginBottom: 15 },
  selectContainer: { position: 'relative', margin: 'auto', marginRight: 16 },
  selectItems: {
    position: 'absolute',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000001C',
    border: '1px solid #F7F7F7',
    borderRadius: '12px',
    marginTop: 11,
    zIndex: 5000,
    height: 300,
    overflow: 'auto',
  },
  successCheck: {
    borderRadius: '50%',
    width: '65px',
    height: '65px',
    backgroundColor: '#00B894',
    margin: 'auto',
    color: '#FFF',
    display: 'flex',
  },
  successMsg: {
    marginTop: '20px',
    textAlign: 'center',
    font: 'normal 600 18px Montserrat',
  },
  items: {
    position: 'absolute',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000001C',
    border: '1px solid #F7F7F7',
    borderRadius: '12px',
    marginTop: 11,
    right: 0,
    zIndex: 5000,
    overflow: 'auto',
  },
  selectItem: {
    '& .MuiTypography-root': {
      font: 'normal normal 600 12px Montserrat',
      color: '#38485C',
      textTransform: 'lowercase',
    },
  },
  filter: {
    marginRight: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  Lastfilter: {
    minWidth: 200,
  },
  filterZone: {
    flexGrow: 1,
    display: 'flex',
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    padding: '10px 7px',
    marginBottom: 11,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  icon: {
    color: '#0681cc !important',
    fontSize: 22,
  },
  table: { minHeight: 400 },
}));

export default useStyles;
