/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import {
  Button, Checkbox, ListItemText, MenuItem, Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditIcon from '@mui/icons-material/Edit';

import useOnClickOutside from 'Shared/Hooks/useClickOutSide';

export const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 3px',
};
export const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
  display: 'block',
};

export const columns = (selectedCustomColumns, setSelectedCustomColumns, classes) => [
  {
    id: 'productId',
    label: 'RÉFÉRENCE',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'designation',
    label: 'DÉSIGNATION',
    style: {
      ...TitleStyle,
      zIndex: 900,
      width: '30%',
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'achatReference',
    label: 'N° FACTUR',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'quantity',
    label: 'QTÉ',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'prixDepart',
    label: 'Prix départ unitaire',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'prixUnitaire',
    label: 'Prix unitaire',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  ...selectedCustomColumns,
  {
    id: 'action',
    label: '',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    renderHeader: () => {
      const [showList, setShowlist] = useState(false);
      const ref = useRef();
      const onChangeColumns = (value) => {
        const index = selectedCustomColumns?.findIndex((c) => c.id === value?.id);
        setSelectedCustomColumns((prev) => {
          let clone = [...prev];
          if (index >= 0) {
            clone.splice(index, 1);
          } else {
            clone = [...clone, value];
          }
          return clone;
        });
      };
      useOnClickOutside(ref, () => setShowlist(false));

      return (
        <div className={classes.selectContainer} ref={ref}>
          <Button
            onClick={() => setShowlist(true)}
            style={{
              backgroundColor: '#FFF',
              width: '35px',
              height: '35px',
              minWidth: '35px',
              borderRadius: '10px',
            }}
          >
            <EditOutlinedIcon style={{ color: '#007BC6' }} />
          </Button>
          {showList && (
            <ColumnList
              classes={classes}
              selectedColumns={selectedCustomColumns}
              onChangeColumns={onChangeColumns}
            />
          )}
        </div>
      );
    },
    render: ({ row }) => (
      <>
        <Button
          size="small"
          onClick={row.triggerDeleteProduct}
          style={{
            width: '30px',
            minWidth: '30px',
            height: '30px',
            backgroundColor: '#FFFFFF',
            borderRadius: '12px',
          }}
        >
          <DeleteOutlineIcon style={{ heught: '15px', width: 'auto', color: '#F86060' }} />
        </Button>
        <Button
          size="small"
          onClick={row.triggerEdit}
          style={{
            marginLeft: '5px',
            width: '30px',
            minWidth: '30px',
            height: '30px',
            backgroundColor: '#FFFFFF',
            borderRadius: '12px',
          }}
        >
          <EditIcon style={{ heught: '15px', width: 'auto' }} />
        </Button>
      </>
    ),
  },
];

export const customColumns = [
  {
    id: 'origine',
    label: 'ORIGINE',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'remise',
    label: 'Remise',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'prixRevient',
    label: 'Prix revient',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'prixRevientTotal',
    label: 'Prix revient total',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'repoId',
    label: 'Dépot',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value === 1 && 'Bouskoura'}
        {value === 2 && 'Derb Omar'}
      </Typography>
    ),
  },
  {
    id: 'ancienPrixRevient',
    label: 'Ancien prix revient',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'ancienPa',
    label: 'Ancien pa',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'ancienPv',
    label: 'Ancien pv',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'hcee',
    label: 'H/CEE',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'tauxDouane',
    label: 'Taux Douane',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'poid',
    label: 'poid',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px', fontWeight: 'bold' }} display="inline">
        {value}
      </Typography>
    ),
  },
];
const ColumnList = ({ classes, onChangeColumns, selectedColumns }) => {
  const onCheck = (value) => () => {
    onChangeColumns(value);
  };
  return (
    <div className={classes.selectItems}>
      {customColumns.map((column) => (
        <MenuItem key={column.id} onClick={onCheck(column)}>
          <Checkbox
            checked={selectedColumns.map((s) => s.id).indexOf(column.id) > -1}
            style={{ color: '#007BC6' }}
          />
          <ListItemText primary={column.label} className={classes.selectItem} />
        </MenuItem>
      ))}
    </div>
  );
};

export const INTERNATION_TRANSPORT = [
  'BOLLORE',
  'CCCM',
  'CMA_CGM',
  'DACHSER',
  'DHL',
  'ECU_WORLD_WIDE',
  'EXMARIS',
  'GEFCO',
  'GEODIS',
  'HAMANN_TRANS',
  'M_AND_M',
  'MSC',
  'OMNILOGISTIC',
  'SMTS',
  'TIMAR',
  'TRANSUNIVERS',
  'MAERSK',
  'SOMAPORT',
  'MARSA_MAROC',
  'IPSEN_LOGISTICS',
  'HAPAG_LLOYD',
  'DB_SCHENKER',
];

export const formatTranportName = (name) => name?.replaceAll('_', ' ');

const getNumValue = (val) => (!val ? 0 : Number(val));
export const getTotalCharge = (document) => {
  if (!document) return 0;
  return Math.round(100 * (getNumValue(document.droitsDouane) + getNumValue(document.pesage)
   + getNumValue(document.interestObligation) + getNumValue(document.magasinage)
   + getNumValue(document.entrepot) + getNumValue(document.testLab)
   + getNumValue(document.surstaris) + getNumValue(document.autresCharges)
   + getNumValue(document.comission) + getNumValue(document.controleCertificat)
   + getNumValue(document.redevanceInfo) + getNumValue(document.coutControle))) / 100;
};

export const sumTotalPrices = (invoices, target = 'totalTtc') => Math.round(100 * invoices
  .reduce((acc, current) => acc + getNumValue(current[target]), 0)) / 100;

export const formaValidation = (form) => (
  (form.repo?.id || form.repoId) && form.productId && form.achatReference);
