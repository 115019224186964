/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const base = (state) => state.app;

export const makeSelectIsLoading = () => createSelector(
  [base], (traceability) => traceability.loading > 0,
);

export const makeSelectFamilies = () => createSelector(
  [base], (traceability) => traceability.families,
);

export const makeSelectBrands = () => createSelector(
  [base], (traceability) => traceability.brands,
);
