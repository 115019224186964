import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import DialogCmp from 'Shared/Dialog';
import EnhancedTable from 'Shared/EnhancedTable';
import { format } from 'date-fns';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import { Box } from '@mui/material';
import { defaultMessage, getCarts, updateCart } from '../SettingsServices';
import { columns, TYPES, STATUS } from './constants';
import Filters from './Filters';
import useForm from '../../../Shared/Hooks/hooks/useForm';

const EditCart = ({
  cart, setOpen, onSubmit, open,
}) => {
  const { values, onChange } = useForm({ souche: null, status: null });
  const onChangeSelect = (name) => (e, value) => {
    onChange({ target: { name, value } });
  };
  const sales = `${cart.commercial?.firstName} ${cart.commercial?.lastName}`;
  useEffect(() => {
    const souche = TYPES.find((item) => (item.id === cart.souche));
    const status = STATUS.find((item) => (item.value === cart.treated));
    onChange({ target: { value: souche, name: 'souche' } });
    onChange({ target: { value: status, name: 'status' } });
  }, [cart]);

  const onHandleSave = () => {
    onSubmit({ ...cart, souche: values.souche.id, treated: values.status.value });
  };
  return (
    <DialogCmp
      title="Modifier"
      subTitle={`Ref: ${cart.id} - ${sales}`}
      open={open}
      handleClose={() => setOpen(false)}
      onHandleSubmit={onHandleSave}
      fullWidth={false}
    >
      <Box style={{
        display: 'flex',
        background: '#E9EBED 0% 0% no-repeat padding-box',
        borderRadius: 12,
        opacity: 1,
        padding: '10px 7px',
        minWidth: '500px',
      }}
      >
        <AutoCompleteCmp
          options={TYPES}
          onChange={onChangeSelect('souche')}
          value={values.souche}
          label="Souche"
          filterOptions={
           (ops, v) => ops.filter((o) => o.name?.toLowerCase().includes(v.inputValue.toLowerCase()))
          }
        />
        <AutoCompleteCmp
          options={STATUS}
          onChange={onChangeSelect('status')}
          value={values.status}
          label="Statut"
          getOptionLabel={(option) => `${option.label}`}
          filterOptions={
              // eslint-disable-next-line max-len
           (ops, v) => ops.filter((o) => o.label?.toLowerCase().includes(v.inputValue.toLowerCase()))
          }
        />
      </Box>
    </DialogCmp>
  );
};
const Carts = () => {
  const [cartsData, setCartsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [cartToEdit, setCartToEdit] = useState(null);
  const [values, setValues] = useState({});

  const handleInputChange = (prop) => (event, value) => {
    if (event?.toString() !== 'Invalid Date') {
      setValues((prev) => ({
        ...prev,
        [prop]: event instanceof Date ? event : value || event?.target.value,
      }));
    }
  };
  const getCartList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        const data = await getCarts(sort, {
          client: values.client?.code,
          commercial: values.commercial?.id,
          startDate: values.startDate ? format(values.startDate, 'yyyy-MM-dd') : undefined,
          endDate: values.endDate ? format(values.endDate, 'yyyy-MM-dd') : undefined,
          treated: values.treated?.value,
        });
        setCartsData(data);
      } catch (e) {
        toast.error(defaultMessage);
      } finally {
        setLoading(false);
      }
    },
    [setCartsData, setLoading, values],
  );
  const onHandleEdit = (row) => {
    setCartToEdit(row);
    setOpenEdit(true);
  };
  const onHandleEnd = (cart, isSuccess) => {
    if (isSuccess) {
      const update = cartsData.content.map((item) => (item.id === cart.id ? cart : item));
      setCartsData({ ...cartsData, content: update });
      toast.success('Cart a été bien modifier');
    } else {
      toast.error('Erreur de sauvegarde!');
    }
    setOpenEdit(false);
    setLoading(false);
    setCartToEdit(null);
  };
  const onHandleSubmit = async (cart) => {
    try {
      setLoading(true);
      await updateCart({ id: cart.id, souche: cart.souche, treated: cart.treated });
      onHandleEnd(cart, true);
    } catch (e) {
      onHandleEnd(cart, false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Filters values={values} handleInputChange={handleInputChange} />
      {/* eslint-disable-next-line max-len */}
      {openEdit && <EditCart open={openEdit} onSubmit={onHandleSubmit} cart={cartToEdit} setOpen={setOpenEdit} />}
      <EnhancedTable
        getData={getCartList}
        rows={cartsData?.content || []}
        headCells={columns(onHandleEdit)}
        count={cartsData?.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default Carts;
