import {
  Box, Checkbox, IconButton, Typography, CardMedia,
  Tooltip,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import UnreadIcon from 'Images/mark_chat_unread.svg';
import RemoveIcon from '@mui/icons-material/Remove';
import { addYearsByNumber } from 'Utils/ulilities';
import ProductContext from './Context';

export const mapOrderStatus = {
  NON_ASSIGNEE: { label: 'Non assignée', class: 'NotAssignedStatus' },
  EN_ATTENTE_PICKAGE: { label: 'En attente', class: 'awaitingPicking' },
  EN_ATTENTE_VALIDATION: { label: 'Validation', class: 'awaitingValidation' },
  EN_COURS_PICKAGE: { label: 'En cours', class: 'pickingInprogress' },
  CLOTUREE: { label: 'Clôturée', class: 'closed' },
  BL_GENERE: { label: 'BL généré', class: 'invoiced' },
  MANQUE: { label: 'Manque', class: 'manque' },
  STOCK: { label: 'Stock', class: 'stock' },
  IMPRIME: { label: 'Imprimé', class: 'closed' },
};
export const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 3px',
  textAlign: 'center',
};
export const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
  display: 'block',
  textAlign: 'center',
};

export const STOCKMIN = 100;
export const STOCK_STATUS = [
  {
    color: 'orange',
    label: 'stock Min',
    value: 'MIN',
    filter: (value) => value < STOCKMIN && value > 0,
  },
  {
    color: 'green',
    label: 'stock Max',
    value: 'MAX',
    filter: (value) => value >= STOCKMIN,
  },
  {
    color: 'red',
    label: 'stock Non Dispo',
    value: 'NON',
    filter: (value) => value <= 0,
  },
];
const getColorByStcockValue = (value) => {
  if (value <= 0) {
    return 'red';
  }
  if (value < STOCKMIN) {
    return 'orange';
  }
  return 'green';
};
const getOutStockDays = (outStockDay) => Math.round(
  ((new Date()).getTime() - (new Date(outStockDay)).getTime()) / (1000 * 3600 * 24),
);
export const columns = (onOpenNotes) => [
  {
    id: 'action',
    label: '',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ row }) => (
      <Checkbox
        checked={row.checked}
        onChange={row.checkProduct}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    ),
    renderHeader: () => (
      <Checkbox color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
    ),
    parentRowStyle: {
      zIndex: 800,
      background: '#f9fafc',
    },
  },
  {
    id: 'reference',
    label: 'RÉFÉRENCE',
    style: {
      ...TitleStyle,
      position: 'sticky',
      left: 0,
      zIndex: 900,
      textAlign: 'left',
    },
    render: ({ value, row }) => (
      <Box style={{ display: 'flex' }}>
        <Typography style={{ ...defaultColumnStyle, width: 70 }} display="inline">
          {value}
        </Typography>
        {onOpenNotes ? (
          <IconButton aria-label="notes" onClick={() => onOpenNotes(row)} size="large">
            {row.hasProductPropositionNotes
              ? <CardMedia component="img" src={UnreadIcon} style={{ width: 20 }} />
              : <ChatBubbleOutlineIcon fontSize="small" />}
          </IconButton>
        ) : null }
      </Box>
    ),
    parentRowStyle: {
      position: 'sticky',
      left: 0,
      zIndex: 800,
      background: '#f9fafc',
    },
  },
  {
    id: 'designation',
    label: 'DÉSIGNATION',
    style: {
      ...TitleStyle,
      zIndex: 900,
      position: 'sticky',
      textAlign: 'center',
      left: 73,
    },
    render: ({ value }) => (
      <Typography
        style={{
          ...defaultColumnStyle,
          width: 250,
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        display="inline"
      >
        {value}
      </Typography>
    ),
    parentRowStyle: {
      position: 'sticky',
      left: 73,
      zIndex: 800,
      background: '#f9fafc',
    },
  },
  {
    id: 'refF',
    label: 'REF FOURNISSEUR',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'priceDevise',
    label: 'PRIX EN DEVISE',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, minWidth: 130 }} display="inline">
        {(value || '-')}
      </Typography>
    ),
  },
  {
    id: 'stock',
    label: 'STOCK ACTUEL',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography
        style={{ ...defaultColumnStyle, color: getColorByStcockValue(value), minWidth: 130 }}
        display="inline"
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'quantityOrder',
    label: 'Quantité à commander',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography
        style={{ ...defaultColumnStyle, color: getColorByStcockValue(value), minWidth: 130 }}
        display="inline"
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'buyedCount',
    label: 'QTÉ ACHETÉ',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, minWidth: 130 }} display="inline">
        {value}
      </Typography>
    ),
    renderHeader: ({ expandColumn, index, expandedRows }) => {
      const {
        state: { expandedColumnsIds },
      } = useContext(ProductContext);
      const [expanded, setExpanded] = useState(false);
      useEffect(() => {
        if (
          expandedColumnsIds.length > 0
          && expandedColumnsIds.includes('buyedCount')
          && !expanded
        ) {
          setExpanded(true);
        }
      }, [expandedColumnsIds, setExpanded, expanded]);
      useEffect(() => {
        if (expandedRows.length === 0) {
          setExpanded(false);
        }
      }, [expandedRows, setExpanded]);
      return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ ...TitleStyle }} display="inline">
            QTÉ ACHETÉ
          </Typography>
          <Box
            style={{
              background: '#007BC6 0% 0% no-repeat padding-box',
              borderRadius: '50%',
              height: 20,
              width: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setExpanded(!expanded);
              expandColumn(index, !expanded);
            }}
          >
            {!expanded && <AddIcon style={{ color: '#FFF', fontSize: 20 }} />}
            {expanded && <RemoveIcon style={{ color: '#FFF', fontSize: 20 }} />}
          </Box>
        </Box>
      );
    },
  },
  {
    id: 'saledCount',
    label: 'QTÉ VENDUES',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, minWidth: 130 }} display="inline">
        {value}
      </Typography>
    ),
    renderHeader: ({ expandColumn, index, expandedRows }) => {
      const {
        state: { expandedColumnsIds },
      } = useContext(ProductContext);
      const [expanded, setExpanded] = useState(false);
      useEffect(() => {
        if (
          expandedColumnsIds.length > 0
          && expandedColumnsIds.includes('saledCount')
          && !expanded
        ) {
          setExpanded(true);
        }
      }, [expandedColumnsIds, setExpanded, expanded]);
      useEffect(() => {
        if (expandedRows.length === 0) {
          setExpanded(false);
        }
      }, [expandedRows, setExpanded]);
      return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ ...TitleStyle }} display="inline">
            QTÉ VENDUES
          </Typography>
          <Box
            style={{
              background: '#007BC6 0% 0% no-repeat padding-box',
              borderRadius: '50%',
              height: 20,
              width: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setExpanded(!expanded);
              expandColumn(index, !expanded);
            }}
          >
            {!expanded && <AddIcon style={{ color: '#FFF', fontSize: 20 }} />}
            {expanded && <RemoveIcon style={{ color: '#FFF', fontSize: 20 }} />}
          </Box>
        </Box>
      );
    },
  },
  {
    id: 'lostSalesNumber',
    label: 'QTÉ VENTE PERDUES',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, minWidth: 130 }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'bcCountInprogress',
    label: 'Commandes en cours',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, minWidth: 130 }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'reliquat',
    label: 'RELIQUAT',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, minWidth: 130 }} display="inline">
        {value || '0'}
      </Typography>
    ),
  },
  {
    id: 'requestedOpsTotal',
    label: 'QTÉ DEMANDE ACHAT',
    style: TitleStyle,
    render: ({ row, value = '' }) => (
      <Tooltip
        title={(
          <Box>
            {row?.requesterQuantities?.map((rq) => (
              <Typography>
                <>
                  {rq.userDto?.firstName}
                  {' '}
                  {rq.userDto?.firstName}
                  {': '}
                  {rq?.quantity}
                </>
              </Typography>
            ))}
          </Box>
        )}
        placement="top"
        arrow
        disableHoverListener={row?.requesterQuantities?.length === 0}
      >
        <Typography style={{ ...defaultColumnStyle, minWidth: 130 }} display="inline">
          {value}
        </Typography>
      </Tooltip>
    ),
  },
  {
    id: 'dateRupture',
    label: 'NOMBRE DE JOUR EN RUPTURE',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, minWidth: 130 }} display="inline">
        {value ? getOutStockDays(value) : 0}
      </Typography>
    ),
  },
];

export const findStartAndEndDate = (values) => {
  let startDate;
  let endDate;
  if (values.startDate && values.endDate) {
    startDate = new Date(values.startDate);
    endDate = new Date(values.endDate);
  } else if (values.startDate) {
    startDate = new Date(values.startDate);
    const expectedEndDate = addYearsByNumber(new Date(startDate), 2);
    endDate = expectedEndDate < new Date() ? expectedEndDate : new Date();
  } else if (values.endDate) {
    endDate = new Date(values.endDate);
    startDate = addYearsByNumber(new Date(endDate), -2);
  } else {
    endDate = new Date();
    startDate = addYearsByNumber(new Date(endDate), -2);
  }
  startDate.setDate(1);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(1);
  return { startDate, endDate };
};
