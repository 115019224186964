import EnhancedTable from 'Shared/EnhancedTable';
import ReferencialContext from 'Shared/ReferencialContext';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import Input from 'Shared/Input/v2';
import { columns } from './constants';
import Filters from './Filters';
import { updateExtra, updateOil } from '../SettingsServices';
import GlobalLoader from '../../../Shared/GlobalLoader/GlobalLoader';
import useForm from '../../../Shared/Hooks/hooks/useForm';
import DialogCmp from '../../../Shared/Dialog';

const EditExtraField = ({
  product, setOpen, onSubmit, open,
}) => {
  const { values, onChange } = useForm(product);
  const onHandleSave = () => {
    onSubmit({ ...values });
  };
  return (
    <DialogCmp
      title="Info supplémentaire"
      subTitle={`Ref: ${product.reference} - ${product.bId} - Famille: ${product.fId}`}
      open={open}
      handleClose={() => setOpen(false)}
      onHandleSubmit={onHandleSave}
      fullWidth={false}
    >
      <Box style={{
        display: 'flex',
        flexDirection: 'column',
        background: '#E9EBED 0% 0% no-repeat padding-box',
        borderRadius: 12,
        opacity: 1,
        padding: '20px 12px',
        minWidth: '600px',
      }}
      >
        <Input label="Info supplémentaire" name="eF" onChange={onChange} value={values?.eF} style={{ maxWidth: 'unset', width: '100%' }} />
      </Box>
    </DialogCmp>
  );
};

const Products = () => {
  const {
    state: {
      referencial: { products = [] },
    },
  } = useContext(ReferencialContext);
  const [values, setValues] = useState({});
  const [productList, setProductList] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAllHuile, setIsAllHuile] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkedOils, setCheckedOils] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      setProductList(products.filter(
        (p) => (!values.search || p.reference.includes(values.search))
                && (!values.brand || p.bId === values.brand.id)
                && (!values.family || p.fId === values.family.id)
                && (!values.huile || p.iH === values.huile.value),
      ));
    },
    [products, values],
  );
  useEffect(() => {
    setIsAllHuile(false);
    setIsAllSelected(false);
    setSelectedRows([]);
    setCheckedOils([]);
  }, [values]);
  useEffect(() => {
    if (isAllSelected) {
      setSelectedRows(productList.map((po) => po.id));
    } else {
      setSelectedRows([]);
    }
  }, [isAllSelected]);
  const handleInputChange = (prop) => (event, value) => {
    if (event?.toString() !== 'Invalid Date') {
      setValues((prev) => ({
        ...prev,
        [prop]: event instanceof Date ? event : value || event?.target.value,
      }));
    }
  };
  const onSelectAll = () => {
    setIsAllSelected(!isAllSelected);
  };
  const onSelectOne = (id) => () => {
    const isExist = selectedRows.find((rowId) => rowId === id);
    if (isExist) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };
  const onHandleChangeOil = async (productRefs, isOil) => {
    try {
      setLoading(true);
      await updateOil({ isOil, productRefs });

      toast.success('Product a été bien modifier');
    } catch (e) {
      toast.error('Erreur de sauvegarde!');
    } finally {
      setLoading(false);
    }
  };
  const onCheckHuile = (id) => () => {
    setProductList(productList.map((po) => {
      if (po.id === id) {
        onHandleChangeOil([id], !po.iH);
        return { ...po, iH: !po.iH };
      }
      return po;
    }));
  };
  const onCheckAllHuile = () => {
    setIsAllHuile(!isAllHuile);
    setCheckedOils(productList.map((po) => po.id));
    if (selectedRows.length > 0) {
      onHandleChangeOil(selectedRows, !isAllHuile);
    }
  };
  const editExtra = (product) => () => {
    setProductToEdit(product);
    setOpenEdit(true);
  };
  const onHandleSubmit = async (product) => {
    try {
      setLoading(true);
      await updateExtra(product);

      toast.success('Product a été bien modifier');
    } catch (e) {
      toast.error('Erreur de sauvegarde!');
    } finally {
      setOpenEdit(false);
      setProductToEdit(null);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Filters
        values={values}
        handleInputChange={handleInputChange}
        countSelectedItem={selectedRows.length}
        isSelected={isAllHuile}
        onCheckAllHuile={onCheckAllHuile}
      />
      {openEdit && productToEdit
      && (
      <EditExtraField
        open={openEdit}
        onSubmit={onHandleSubmit}
        product={productToEdit}
        setOpen={setOpenEdit}
      />
      )}
      <EnhancedTable
        staticData
        rows={productList}
        headCells={columns(
          // eslint-disable-next-line max-len
          onSelectAll, onSelectOne, onCheckHuile, editExtra, isAllSelected, selectedRows, checkedOils, isAllHuile,
        )}
        count={productList?.length}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default Products;
