/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: 0,
  families: {
    map: {},
    list: [],
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoadingOn: (state) => {
      state.loading += 1;
    },
    setAppLoadingOff: (state) => {
      state.loading -= 1;
    },
    setAppFamilies: (state, action) => {
      state.families.list = action.payload;
      state.families.map = action.payload.reduce((acc, family) => {
        acc[family.id] = family;
        return acc;
      }, {});
    },
    setAppBrands: (state, action) => {
      state.brands = action.payload;
    },
  },
});

export const {
  setAppLoadingOn, setAppLoadingOff, setAppFamilies, setAppBrands,
} = appSlice.actions;

export default appSlice.reducer;
