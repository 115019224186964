import {
  Box, Button, FilledInput, FormControl, InputLabel,
} from '@mui/material';
import useForm from 'Shared/Hooks/hooks/useForm';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import useStyles from './style-add';
import { addNote, defaultMessage } from '../ClientServices';

const AddNoteSAV = ({ client, refreshClient }) => {
  const [loading, setLoading] = useState(false);
  const { values, onChange } = useForm({ comment: '' });
  const classes = useStyles();

  const addNoteData = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-case-declarations
      const formData = new FormData();
      if (values.comment) {
        formData.append('comment', values.comment);
      }
      formData.append('clientCode', client.code);
      await addNote(formData);
      toast.success('Note ajouté avec succès');
      refreshClient();
      onChange({ target: { name: 'comment', value: '' } });
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <FormControl
        fullWidth
        style={{
          flexGrow: 1,
        }}
      >
        <InputLabel htmlFor="component-filled" className={classes.label}>
          Commentaire ...
        </InputLabel>
        <FilledInput
          id="component-filled"
          value={values.comment}
          onChange={onChange}
          name="comment"
          multiline
          fullWidth
          rows={8}
          classes={{ root: classes.inputBase, input: classes.customFilledInput }}
        />
      </FormControl>
      <Box style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Button
          variant="contained"
          style={{
            background: '#007BC6 0% 0% no-repeat padding-box',
            border: '1px solid #007BC6',
            borderRadius: 12,
            opacity: 1,
            font: 'normal normal bold 18px/22px Montserrat',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            marginBottom: 10,
            height: 45,
          }}
          disabled={!values.comment}
          onClick={addNoteData}
        >
          Ajouter la note
        </Button>
      </Box>
    </>
  );
};

export default AddNoteSAV;
