import React, { useEffect, useMemo, useState } from 'react';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { Box } from '@mui/material';
import useForm from 'Shared/Hooks/hooks/useForm';
import { withoutTime } from 'Utils/ulilities';
import CustomTablePagination from 'Shared/CustomTablePagination';
import useStyles from './style';
import PropositionItem from './PropositionItem';
import { getPropositions } from './PropositionServices';
import PropositionFilters from './PropositionFilters';

const Proposition = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { values, onChange } = useForm({ search: '', date: null, status: '' });
  const [propositions, setPropositions] = useState({ content: [], totalElements: 0 });
  const propositionList = useMemo(
    () => propositions.content.filter(
      (item) => (!values.date
        || withoutTime(values.date)?.getTime() === withoutTime(item.date)?.getTime())
      ,
    ),
    [values, propositions],
  );

  const getPropositionData = async () => {
    setLoader(true);
    const response = await getPropositions({
      page,
      size: rowsPerPage,
      name: values.search.trim(),
      statusList: values.status ? [values.status] : [],
    });
    setPropositions(response);
    setLoader(false);
  };

  useEffect(async () => {
    getPropositionData();
  }, [rowsPerPage, page, values.search, values.status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onUpdateProposition = (id, status) => {
    const newPro = propositions.content.map((p) => (p.id === id ? { ...p, status } : p));
    setPropositions({ ...propositions, content: newPro });
  };
  return (
    <div style={{ height: '100vh' }}>
      {loader && <GlobalLoader size="100vh" />}
      <div className={classes.container}>
        <PropositionFilters values={values} onChange={onChange} />
        {propositionList.map((proposition) => (
          <PropositionItem
            onUpdateProposition={onUpdateProposition}
            key={proposition.id}
            reference={proposition.id}
            {...proposition}
            setLoader={setLoader}
            reload={getPropositionData}
          />
        ))}
        <Box className={classes.pagination}>
          <CustomTablePagination
            labelRowsPerPage="Lignes par page"
            rowsPerPageOptions={[10, 20, 25]}
            component="div"
            count={propositions.totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </div>
    </div>
  );
};

export default Proposition;
