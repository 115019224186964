import {
  Box, Popper, TextField, Autocomplete,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ButtonApp from 'Shared/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateCopima } from 'Shared/RangeDate';
import useStyles from './style';

const PopperMy = (props) => <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;
const STATUS_LIST = ['CREATED', 'VALIDATED'];

const PropositionFilters = ({
  values, onChange, onHandleRemove, totalSelected = 0,
}) => {
  const classes = useStyles();

  const handleInputChange = (event) => {
    onChange({ target: { value: event, name: 'date' } });
  };
  const handleSelectChange = (event, value) => {
    onChange({ target: { value, name: 'status' } });
  };
  return (
    <Box className={classes.filterContainer}>
      <Box className={classes.filterZone}>
        <TextField
          classes={{ root: classes.filter }}
          placeholder="Chercher par nom"
          variant="outlined"
          size="small"
          name="search"
          className={classes.filter}
          style={{ width: 200 }}
          InputProps={{
            classes: { notchedOutline: classes.notched },
            startAdornment: <SearchIcon style={{ color: '#AFB0B2' }} />,
          }}
          InputLabelProps={{
            classes: { root: classes.label },
          }}
          value={values.search}
          onChange={onChange}
          onKeyDown={onChange}
          // disabled={values.isMiniSearch}
        />
        { values.isMiniSearch ? null : (
          <DateCopima
            variant="inline"
            format="dd/MM/yyyy"
            id="date"
            label="Date"
            name="date"
            style={{ height: '100%' }}
            className={classes.date}
            value={values.date || null}
            onChange={handleInputChange}
          />
        )}
        { values.isMiniSearch ? null : (
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={STATUS_LIST || []}
            onChange={handleSelectChange}
            value={values.status}
            size="small"
            name="status"
            style={{ height: '100%', width: 170 }}
            autoHighlight
            getOptionLabel={(option) => option}
            ListboxComponent={ListboxComponent}
            filterOptions={
            (options, v) => (
              options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))
            )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="status"
                label="Statut"
                variant="outlined"
              /* inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }} */
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        )}
        { values.canRemove ? (
          <ButtonApp
            onClick={onHandleRemove}
            startIcon={<DeleteIcon />}
            label={`Supprimer La Selection (${totalSelected})`}
            style={{
              marginLeft: 'auto',
              background: '#F86060',
              boxShadow: '0px 4px 10px #BEBEBE50',
            }}
          />
        ) : null }
      </Box>
    </Box>
  );
};

export default PropositionFilters;
